import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  IonButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonText,
  IonFooter, 
} from "@ionic/react";
import React, { useState } from "react";
import {
  checkboxOutline,
  bookOutline,
  searchOutline,
} from "ionicons/icons";
import "./Home.css";
import Auth from "../components/Auth";

const Home: React.FC<any> = ({ history }) => {
  const [showmodal, setshowmodal] = useState(false);
  const [showModal2, setshowmodal2] = useState(false);

  const checktrue = true;
  const UserLoginFunction = () => {
    Auth.login(UserLoginCallback, checktrue);
    setshowmodal(true);
  };

  const UserLoginFunction2 = () => {
    Auth.login(UserLoginCallback2, checktrue);
  };

  const UserLoginCallback = () => {
    history.replace({
      // pathname: `/typereq`,
      pathname: `/covidreg`,
    });
  };

  const UserLoginCallback2 = () => {
    setshowmodal2(false);
    history.replace({
      // pathname: `/typereq`,
      pathname: `/monitoring`,
    });
  };

  const Homelink = () => {
    setshowmodal2(false);
    history.replace({
      // pathname: `/typereq`,
      pathname: `/Home`,
    });
  };

  const RenderModals = () => {
    return (
      <IonModal isOpen={showmodal} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar className="tabcolor">
            <IonTitle>Covid-19 Test Guidelines</IonTitle>
            <IonRow slot="end"></IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
            <IonRow>
              <IonCol>
                <pre>
                  <img
                    src="https://api.spcmc.online/assets/RTPCR%20Guides.jpg"
                    alt="Guides"
                  ></img>
                </pre>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  className="ion-margin "
                  type="submit"
                  color="success"
                  onClick={() => setshowmodal(false)}
                >
                  I Understand / Naiintindihan Ko
                  <IonIcon slot="start" icon={checkboxOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    );
  };

  const RenderModals2 = () => {
    return (
      <IonModal isOpen={showModal2} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar className="tabcolor">
            <IonTitle>EULA</IonTitle>
            <IonRow slot="end"></IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
            <IonRow>
              <IonText>Privacy Policy</IonText>
              <IonText>
                San Pablo Colleges Medical Center (SPCMC) is committed to
                protecting the privacy of our online visitors. This privacy
                policy (“Privacy Policy”) describes how we handle the personal
                information that you provide to us on this website
                https://book.spcmc.online/, accessible from
                https://book.spcmc.online/ It is your right to be informed how
                this hospital collects and uses your website information. Please
                be advised that practices described in this Privacy Policy apply
                only to information gathered online at our Website. Please be
                sure to read this Privacy Policy before using this Web Site or
                submitting information to us. Using this website means that you
                accept this Privacy Policy. Please check this area periodically
                for Privacy Policy updates.
              </IonText>

              <IonText>
                INFORMATION SPCMC COLLECTS FROM YOU <br></br>
                <br></br>We collect information from our visitors through IP
                addresses tracking, cookies and access time at the Web Site. We
                may also ask you for specific information which include but not
                limited to personally identifiable information, such as name,
                e-mail address, contact number, etc. Although we do not intend
                to collect personal health information, to the extent any
                personal health information is collected, please see our Privacy
                Notice.
              </IonText>
              <IonText>
                {" "}
                use your personal information for the following purposes
              </IonText>
              <IonText>
                HOW SPCMC PROTECTS YOUR INFORMATION We work hard to protect your
                personal information. We use the most effective technical
                security measures to maintain the integrity and security of our
                databases including SSL encryption for data transfer, firewalls,
                restricted access to data and employee training. Only authorized
                personnel have access to your personal information and inquiries
                in our files.{" "}
              </IonText>
              <IonText>
                The SPCMC website use cookies to enhance the usability of our
                webpage. We do not store any personal information on our website
                or database.
              </IonText>
              <IonText>THIRD PARTY LINKS</IonText>
              <IonText>
                This Web Site does not contain links to other websites operated
                by third parties.{" "}
              </IonText>
              <IonText>
                We do not share your personal information with third parties
                unless: You have consented to the sharing thereof <br></br>
                <br></br>
                It is necessary to protect our interest <br></br>
                <br></br>
                When required by law<br></br>
                <br></br>
                With service providers acting on our behalf who have agreed to
                protect the confidentiality of the data<br></br>
                With HMOs and/or Companies with whom you are affiliated with,
                and with who you consented to the sharing thereof.<br></br>
              </IonText>
              <IonText>
                OUR COOKIE POLICY Once you agree to allow our website to use
                cookies, you also agree to use the data it collects regarding
                your online behavior. Please note that cookies don’t allow us to
                gain control of your computer in anyway. You can instruct your
                browser to refuse all cookies or to indicate when cookie is
                being sent. However, if you do not accept cookies, you may not
                be able to use some portions of our Site.
              </IonText>
              <IonText>RIGHTS OF THE DATA SUBJECTS</IonText>
              <IonText>
                Under the data Privacy Act of 2012, you have the right to the
                following:
              </IonText>
              <IonText class="ul">
                <IonText class="list">
                  To be informed of the collection and processing of your
                  personal information;
                </IonText>
                <IonText class="list">
                  To object to the processing of your personal information;
                </IonText>
                <IonText class="list">
                  To access your personal information and correct inaccuracies
                  or errors of your entries;
                </IonText>
                <IonText class="list">
                  To suspend, withdraw, or order the blocking, removal, or
                  destruction of your personal information from our filing
                  system;{" "}
                </IonText>
                <IonText class="list">
                  To complain due to such inaccuracies, incomplete, outdated,
                  false, unlawfully obtained or unauthorized use of personal
                  information; and{" "}
                </IonText>
                <IonText class="list">
                  To obtain copy of such data in an electronic or structured
                  format where your personal information is processed by
                  electronic means and in a structured and commonly used format.{" "}
                </IonText>
              </IonText>
              <IonText>CONTACT INFORMATION </IonText>
              <IonText>
                For your concerns regarding data privacy, you may reach our Data
                Privacy Officer at{" "}
              </IonText>
              <IonText>at dataprivacy@spcmc.com.ph. </IonText>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  className="ion-margin "
                  type="submit"
                  color="success"
                  onClick={UserLoginFunction2}
                >
                  accept
                  {/* <IonIcon slot="start" icon={} /> */}
                </IonButton>
                <IonButton
                  className="ion-margin "
                  type="submit"
                  color="success"
                  onClick={Homelink}
                >
                  logout
                  {/* <IonIcon slot="start" icon={} /> */}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    );
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder ">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonCol>
              {/* <IonTitle>SPCMC Booking App</IonTitle> */}
              <IonRow>
                <img
                  src="assets/icon/logowhite.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>

                <IonText className="imgcontainer">
                  SPCMC Online Services <br></br>
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent className="backgroundmain">
        <IonGrid className="maingrid">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                shape="round"
                type="submit"
                color="light"
                onClick={UserLoginFunction}
              >
                Request A Covid Test
                <IonIcon slot="start" icon={bookOutline} />
              </IonButton>
            </IonCol>

            <IonCol className="ion-text-center">
              <IonButton
                shape="round"
                type="submit"
                color="light"
                onClick={() => {
                  setshowmodal2(true);
                }}
              >
                Request Other Procedure
                <IonIcon slot="start" icon={searchOutline} />
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow></IonRow>
        </IonGrid>
        {RenderModals()}
        {RenderModals2()}
      </IonContent>

      {/* <IonItem>
      <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3870.1114556875264!2d121.30465401534009!3d14.070595193615686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33bd5d2af4ba3ac1%3A0x7fb45f3a3231f35b!2sSPC%20Medical%20Center!5e0!3m2!1sen!2sph!4v1642222297520!5m2!1sen!2sph"
          width="100%"
          height="300px"
        ></iframe>
        </IonItem> */}

      <IonFooter className="ion-text-center">
        <IonToolbar>
          <IonText>
            © 2022 SAN PABLO COLLEGES MEDICAL CENTER. ALL RIGHTS RESERVED.
          </IonText>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
