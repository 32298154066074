import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  IonHeader,
  IonToolbar,
  IonCard,
  IonItem,
  IonCardContent,
  IonText,
  IonCardTitle,
  IonCardHeader,
} from "@ionic/react";
import React from "react";
import "./Home.css";
import Auth from "../components/Auth";

const TypeofReq: React.FC<any> = ({ history }) => {
  const typeCallback = () => {
    history.replace({
      pathname: `/covidreg`,
    });
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder ">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonCol>
              <IonRow>
                <img
                  src="assets/icon/logowhite.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol>
            <IonCardTitle className="ion-margin-horizontal ion-margin-top ">
              {" "}
              <IonText className="ion-align">FEATURED PRODUCTS</IonText>
            </IonCardTitle>
          </IonCol>
        </IonRow>
        <IonGrid>
          <form>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Type of Service</IonCardTitle>
                  </IonCardHeader>
                  <IonItem
                    button
                    onClick={() => {
                      Auth.typeoftestd(typeCallback, "0");
                    }}
                  >
                    <IonCardTitle>
                      {" "}
                      <IonText>
                        <b>
                          Reverse transcription-polymerase chain reaction
                          (RT-PCR)
                        </b>
                      </IonText>
                    </IonCardTitle>
                    <IonCardContent>
                      <b>Laboratory</b>- (P 5,000.00)<br></br>
                      <b>Home Service</b>- (5,000.00 + Premium Fee) - please
                      call <b> 0975 949-0236 </b> for the rates<br></br>
                      <b>Drive-Thru</b>- (P 5,000.00<br></br>
                    </IonCardContent>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Type of Service</IonCardTitle>
                  </IonCardHeader>
                  <IonItem
                    button
                    onClick={() => {
                      Auth.typeoftestd(typeCallback, "1");
                    }}
                  >
                    <IonCardTitle>
                      {" "}
                      <IonText>
                        <b>Rapid Antigen</b>
                      </IonText>
                    </IonCardTitle>
                    <IonCardContent>
                      <b>Laboratory</b>- (P 2,000.00)<br></br>
                      <b>Home Service</b>- (2,000.00 + Premium Fee) - please
                      call <b> 0975 949-0236 </b> for the rates<br></br>
                      <b>Drive-Thru</b>- (P 2,000.00<br></br>
                    </IonCardContent>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default TypeofReq;
