import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonIcon,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonCheckbox,
  IonCard,
  IonItemGroup,
  IonItemDivider,
  IonCardTitle,
  IonRadioGroup,
  IonAlert,
  IonRadio,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Moment from "moment";
import {
  addCircleSharp,
  calendarOutline,
  copyOutline,
  logIn,
  manOutline,
  save,
} from "ionicons/icons";
import "./Home.css";
import Auth from "../components/Auth";

interface Region {
  PK_addregion: number;
  addregion: String;
  FK_addregion: number;
}

interface Province {
  PK_addprovince: number;
  FK_addregion: number;
  provincename: String;
}

interface Municipality {
  PK_addmuncity: number;
  FK_addprovince: number;
  muncityname: String;
  zipcode: number;
}

interface Brgy {
  PK_addbrgy: number;
  FK_addmuncity: number;
  brgyname: String;
}

var Barcode = require("react-barcode");

const CovidReg: React.FC<any> = ({ history }) => {
  const [DataSource, setDataSource] = useState<Region[]>([]);

  const [DataSource1, setDataSource1] = useState<Province[]>([]);

  const [DataSource2, setDataSource2] = useState<Municipality[]>([]);

  const [DataSource3, setDataSource3] = useState<Brgy[]>([]);

  const Datenow = Moment(new Date()).format("YYYY-MM-DD");

  const Datenow2 = Moment(new Date()).format("dddd, MMMM Do YYYY hh:mm a");

  //MODAL

  const [showalert, setshowalert] = useState(false);
  const [region, setregion] = useState(0);
  const [province, setprovince] = useState(0);
  const [muncity, setmuncity] = useState(0);
  const [brgy, setbrgy] = useState(0);
  // eslint-disable-next-line
  const [regiondata, setregiondata] = useState("");
  const [provincedata, setprovincedata] = useState("");
  const [muncitydata, setmuncitydata] = useState("");
  const [brgydata, setbrgydata] = useState("");
  const [addother, setaddother] = useState("");

  //START

  const [philhealthnum, setphilhealthnum] = useState("");
  const [namelast, setnamelast] = useState("");
  const [namefirst, setnamefirst] = useState("");
  const [namemiddle, setnamemiddle] = useState("");
  const [namesuffix, setnamesuffix] = useState("");
  const [datebirth, setdatebirth] = useState<string>(Datenow);
  const [gender, setgender] = useState("1");
  const [civilstatus, setcivilstatus] = useState("");
  const [nationality, setnationality] = useState("");
  const [occupation, setoccupation] = useState("");

  const [cahousest, setcahousest] = useState("");
  const [cabrgy, setcabrgy] = useState("");
  const [camuncity, setcamuncity] = useState("");
  const [caprovince, setcaprovince] = useState("");
  const [cahomephoneno, setcahomephoneno] = useState("");
  const [cacellphoneno, setcacellphoneno] = useState("");
  const [caemailadd, setcaemailadd] = useState("");

  const [cwhousest, setcwhousest] = useState("");
  const [cwbrgy, setcwbrgy] = useState("");
  const [cwmuncity, setcwmuncity] = useState("");
  const [cwprovince, setcwprovince] = useState("");
  const [cwhomephoneno, setcwhomephoneno] = useState("");
  const [cwcellphoneno, setcwcellphoneno] = useState("");
  const [cwemailadd, setcwemailadd] = useState("");

  const [caipreconst, setcaipreconst] = useState("0");
  const [caidatefconsult, setcaidatefconsult] = useState<string>(Datenow);
  const [cainfacifirst, setcainfacifirst] = useState("");
  const [caicaseadmfname, setcaicaseadmfname] = useState("");
  const [caicaseadmrpf, setcaicaseadmrpf] = useState("");

  const [dpadmhoschk, setdpadmhoschk] = useState(false);
  const [dpadmhos, setdpadmhos] = useState("");
  const [dpadmhosdt, setdpadmhosdt] = useState<string>(Datenow);

  const [dpadmisochk, setdpadmisochk] = useState(false);
  const [dpadmiso, setdpadmiso] = useState("");
  const [dpadminsodt, setdpadminsodt] = useState<string>(Datenow);

  const [dhhomechk, setdhhomechk] = useState(false);
  const [dhhomedt, setdhhomedt] = useState<string>(Datenow);

  const [sphcwchk, setsphcwchk] = useState("0");
  const [sphcw, setsphcw] = useState("");

  const [spofwchk, setspofwchk] = useState("0");
  const [spofw, setspofw] = useState("");

  const [sppfnt, setsppfnt] = useState("0");
  const [sppfntorigin, setsppfntorigin] = useState("");

  const [splsichk, setsplsichk] = useState("0");
  const [splsi, setsplsi] = useState("");

  const [splcschk, setsplcschk] = useState("0");
  const [splcs, setsplcs] = useState("");
  const [splcsname, setsplcsname] = useState("");

  const [pachousest, setpachousest] = useState("");
  const [pacbrgy, setpacbrgy] = useState("");
  const [pacmuncity, setpacmuncity] = useState("");
  const [pacprovince, setpacprovince] = useState("");
  const [pachomephoneno, setpachomephoneno] = useState("");
  const [paccellphoneno, setpaccellphoneno] = useState("");
  const [pacemailadd, setpacemailadd] = useState("");

  const [clasymp, setclasymp] = useState(false);
  const [clfever, setclfever] = useState(false);
  const [clfevertext, setclfevertext] = useState(0.0);
  const [clcough, setclcough] = useState(false);
  const [clgene, setclgene] = useState(false);
  const [clfatigue, setclfatigue] = useState(false);
  const [clheadache, setclheadache] = useState(false);
  const [clmyala, setclmyala] = useState(false);
  const [clsoreth, setsoreth] = useState(false);
  const [clcoryz, setcloryz] = useState(false);
  const [cldysp, setcldysp] = useState(false);
  const [clanore, setclanore] = useState(false);
  const [clnau, setclnau] = useState(false);
  const [clvomit, setclvomit] = useState(false);
  const [cldiar, setcldiar] = useState(false);
  const [clams, setclams] = useState(false);
  const [clanos, setclanos] = useState(false);
  const [clageu, setclague] = useState(false);
  const [clothers, setclothers] = useState(false);
  const [clotherstext, setclotherstext] = useState("");

  const [comonone, setcomonone] = useState(false);
  const [comohyper, setcomohyper] = useState(false);
  const [comodiab, setcomodiab] = useState(false);
  const [comoheartd, setcomohearted] = useState(false);
  const [comolungd, setcomolungd] = useState(false);
  const [comogastro, setcomogastro] = useState(false);
  const [comogenit, setcomogenit] = useState(false);
  const [comoneuro, setcomoneuro] = useState(false);
  const [comocancer, setcomocancer] = useState(false);
  const [comoother, setcomoother] = useState(false);
  const [comoothertext, setcomoothertext] = useState("");
  const [preglmp, setpreglmp] = useState("0");
  const [lmp, setlmp] = useState(Datenow);
  const [highrisk, sethighrisk] = useState("0");

  const [exhistory, setexhistory] = useState("2");
  const [exdatecont, setexdatecont] = useState<string>(Datenow);
  const [explacetrans, setexplacetrans] = useState("2");

  const [exhfacichk, setexhfacichk] = useState(false);
  const [exhfacidetail, setexhfacidetail] = useState("");
  const [exhfacidatevisit, setexhfacidatevisit] = useState<string>(Datenow);

  const [excschk, setexcschk] = useState(false);
  const [excsdetail, setexcsdetail] = useState("");
  const [excsdate, setexcsdate] = useState<string>(Datenow);

  const [exmarketchk, setexmarketchk] = useState(false);
  const [exmarketdetail, setexmarketdetail] = useState("");
  const [exmarketdetaildate, setexmarketdetaildate] = useState<string>(Datenow);

  const [exschoolchk, setexschoolchk] = useState(false);
  const [exschooldetail, setexschooldetail] = useState("");
  const [exschooldetaildate, setexschooldetaildate] = useState<string>(Datenow);

  const [extranspochk, setextranspochk] = useState(false);

  const [explacechk, setexplacechk] = useState(false);
  const [explacedetail, setexplacedetail] = useState("");
  const [explacedetaildate, setexplacedetaildate] = useState<string>(Datenow);

  const [exsocialgathchk, setexsocialgathchk] = useState(false);
  const [exsocialgathdetail, setexsocialgathdetail] = useState("");
  const [exsocialgathdetaildate, setexsocialgathdetaildate] =
    useState<string>(Datenow);

  const [exotherchk, setexotherchk] = useState(false);
  const [exotherdetail, setexotherdetail] = useState("");
  const [exotherdetaildate, setexotherdetaildate] = useState<string>(Datenow);
  const [tronair, settronair] = useState("");
  const [tronfnum, settronfnum] = useState("");
  const [trondatedept, settrondatedept] = useState<string>(Datenow);
  const [trondatearri, settrondatearri] = useState<string>(Datenow);

  const [exname1, setexname1] = useState("");
  const [exname2, setexname2] = useState("");
  const [exname3, setexname3] = useState("");
  const [exname4, setexname4] = useState("");
  const [excontact1, setcontact1] = useState("");
  const [excontact2, setcontact2] = useState("");
  const [excontact3, setcontact3] = useState("");
  const [excontact4, setcontact4] = useState("");

  const [trnameinfor, settrnameinfor] = useState("");
  const [trrelaship, settrrelaship] = useState("");
  const [trcontact, settrcontact] = useState("");

  // END
  const [refcovidid, setrefcovidid] = useState("");
  const [refmodalnumbmod, setrefmodalnumbmod] = useState(false);
  const [refmodaladdress, setrefmodaladdresss] = useState(false);

  const [dateprefered, setprefered] = useState<string>(Datenow);

  const [isHosprec, setHosprec] = useState(false);

  const [checkadd, setcheckadd] = useState(0);
  const [sigsymp, setsigsymp] = useState("0");
  const [comor, setcomor] = useState("0");
  const [covidbefore, setcovidbefore] = useState("0");
  const [ycovidlab, setycovidlab] = useState("");
  const [ydspeci, setydspeci] = useState<string>(Datenow);
  const [nswabs, setnswabs] = useState("");
  const [tservice, settservice] = useState("0");

  const [ttesttype, setttesttype] = useState("0");
  const [wclosesetting, setwclosesetting] = useState("0");
  const [spofwyes, setspofwyes] = useState("0");
  const [sppfnttype, setsppfnttype] = useState("0");
  // const [spipchk, setspipchk] = useState("0");
  // const [iptext, setiptext] = useState("");
  const [dofill, setdofill] = useState("");
  const [ari, setari] = useState("0");
  const [intcof, setintcof] = useState("");
  const [intdateof, setintdateof] = useState(Datenow);
  const [intdateto, setintdateto] = useState(Datenow);
  const [inttrans, setinttrans] = useState("0");

  const [exhfaciadd, setexhfaciadd] = useState("");
  const [exhfacidateto, setexhfacidateto] = useState(Datenow);
  const [exfacitrans, setexfacitrans] = useState("0");

  const [excsadd, setexcsadd] = useState("");
  const [excsdateto, setexcsdateto] = useState(Datenow);
  const [excstrans, setexcstrans] = useState("0");

  const [exschooladd, setexschooladd] = useState("");
  const [exschooldateto, setexschooldateto] = useState(Datenow);
  const [exschooltrans, setexschooltrans] = useState("0");

  const [explaceadd, setexplaceadd] = useState("");
  const [explacedateto, setexplacedateto] = useState(Datenow);
  const [exexplacetrans, setexexplacetrans] = useState("0");

  const [exmarketadd, setexmarketadd] = useState("");
  const [exmarketdateto, setexmarketdateto] = useState(Datenow);
  const [exmarkettrans, setexmarkettrans] = useState("0");

  const [exsocialgathadd, setexsocialgathadd] = useState("");
  const [exsocialgathdateto, setexsocialgathdateto] = useState(Datenow);
  const [exsocialgathtrans, setexsocialgathtrans] = useState("0");

  const [exotheradd, setexotheradd] = useState("");
  const [exotherdateto, setexotherdateto] = useState(Datenow);
  const [exothertrans, setexothertrans] = useState("0");

  const [extransvessel, setextransvessel] = useState("");
  const [extransvessel2, setextransvessel2] = useState("");

  const [extransfvessel, setextransfvessel] = useState("");
  const [extransfvessel2, setextransfvessel2] = useState("");

  const [extransorigin, setextransorigin] = useState("");
  const [extransorigin2, setextransorigin2] = useState("");

  const [extransdatedep, setextransdatedep] = useState(Datenow);
  const [extransdatedep2, setextransdatedep2] = useState(Datenow);

  const [extransdesti, setextransdesti] = useState("");
  const [extransdesti2, setextransdesti2] = useState("");

  const [extransarri, setextransarri] = useState(Datenow);
  const [extransarri2, setextransarri2] = useState(Datenow);

  const [splocfaci, setsplocfaci] = useState("");
  const [copassport, setcopassport] = useState("");
  const [fntpassport, setfntpassport] = useState("");
  const [vaciyes, setvaciyes] = useState(false);
  const [datevaci1, setdatevaci1] = useState(Datenow);
  const [datevaci2, setdatevaci2] = useState(Datenow);
  const [namevaci1, setnamevaci1] = useState("");
  const [namevaci2, setnamevaci2] = useState("");
  const [dosevaci1, setdosevaci1] = useState("");
  const [dosevaci2, setdosevaci2] = useState("");
  const [vaccicen1, setvaccicen1] = useState("");
  const [vaccicen2, setvaccicen2] = useState("");
  const [regfacivaci1, setregfacivaci1] = useState("");
  const [regfacivaci2, setregfacivaci2] = useState("");
  const [adversevaci1, setadversevaci1] = useState("");
  const [adversevaci2, setadversevaci2] = useState("");

  const [isemployee, setisemployee] = useState(false);
  // const [ttestPackage, setttestPackage] = useState("0");

  useEffect(() => {
    FindRegionAdd();
    // eslint-disable-next-line
  }, []);

  // function PrintRefno() {
  //   fetch(Auth.globalwebhost + "/print3/example/interface/windows-usb.php", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       refcovidid: refcovidid,
  //     }),
  //   });
  //   setrefmodalnumbmod(false);
  //   history.replace({
  //     pathname: `/home`,
  //   });
  // }

  function FindRegionAdd() {
    // if (Auth.typeoftest === "0") {
    //   settypeholder("Reverse transcription polymerase chain reaction (RT-PCR)");
    // } else if (Auth.typeoftest === "1") {
    //   settypeholder("Rapid Antigen");
    // }
    fetch(Auth.globalwebhost + "bookingapp/address/addregion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);

        setDataSource(responseJson);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addprovince", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson1) => {
        // console.log(responseJson);

        setDataSource1(responseJson1);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addmuncity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson2) => {
        // console.log(responseJson);

        setDataSource2(responseJson2);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addbrgy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson3) => {
        // console.log(responseJson);

        setDataSource3(responseJson3);
      });
  }

  function RenderAlert() {
    return (
      <IonGrid>
        <IonModal isOpen={refmodalnumbmod} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Generated Reference Number</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal ion-text-center">
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>SPCMC BOOKING APP</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                {/* <IonCol>{refcovidid}</IonCol> */}
                <IonCol className="ion-text-center">
                  <Barcode value={refcovidid} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText> Date Submitted: {Datenow2}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>Date of Swabbing: {dateprefered}</IonText>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>
                    {" "}
                    <b>
                    "Your Covid Registration is Confirmed. Please Call 0975 949 0236 for Confirmation."                  
                    </b>
                  </IonText>
                </IonCol>
              </IonRow>

              {/* <IonRow className="ion-text-center">
                <IonCol>
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={PrintRefno}
                  >
                    Print Reference Number
                    <IonIcon slot="start" icon={print} />
                  </IonButton>
                </IonCol>
              </IonRow>  */}

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={() => {
                      history.replace({
                        pathname: `/home`,
                      });

                      setrefmodalnumbmod(false);
                    }}
                  >
                    Close
                    <IonIcon slot="start" icon={save} />
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonLabel>
                    <IonText color="danger">*</IonText>
                    Please Take a Picture of your Reference Number
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    );
  }
  function RenderAddress() {
    return (
      <IonGrid>
        <IonModal isOpen={refmodaladdress} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Find Address</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal ion-text-center">
              <IonItem>
                <IonLabel>Region</IonLabel>
                <IonSelect
                  placeholder="Please select a [Region]."
                  value={region}
                  onIonChange={(e: any) => {
                    setregion(e.target.value);
                    const regiond = DataSource.filter(
                      (x) => x.PK_addregion === e.detail.value
                    );
                    setregiondata(regiond[0].addregion.toString());
                  }}
                >
                  {DataSource.map((m) => (
                    <IonSelectOption
                      key={m.PK_addregion}
                      value={m.PK_addregion}
                    >
                      {m.addregion}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Province</IonLabel>
                <IonSelect
                  placeholder="Please select a [Province]."
                  value={province}
                  onIonChange={(e: any) => {
                    setprovince(e.target.value);
                    const regiond = DataSource1.filter(
                      (x) => x.PK_addprovince === e.detail.value
                    );
                    setprovincedata(regiond[0].provincename.toString());
                  }}
                >
                  {DataSource1.filter(
                    (DataSource1) => DataSource1.FK_addregion === region
                  ).map((m) => (
                    <IonSelectOption
                      key={m.PK_addprovince}
                      value={m.PK_addprovince}
                    >
                      {m.provincename}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Municipal</IonLabel>
                <IonSelect
                  placeholder="Please select a [Municipality]."
                  value={muncity}
                  onIonChange={(e: any) => {
                    setmuncity(e.target.value);
                    const regiond = DataSource2.filter(
                      (x) => x.PK_addmuncity === e.detail.value
                    );
                    setmuncitydata(regiond[0].muncityname.toString());
                  }}
                >
                  {DataSource2.filter(
                    (DataSource2) => DataSource2.FK_addprovince === province
                  ).map((m) => (
                    <IonSelectOption
                      key={m.PK_addmuncity}
                      value={m.PK_addmuncity}
                    >
                      {m.muncityname}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Barangay</IonLabel>
                <IonSelect
                  placeholder="Please select a [Barangay]."
                  value={brgy}
                  onIonChange={(e: any) => {
                    setbrgy(e.target.value);
                    const regiond = DataSource3.filter(
                      (x) => x.PK_addbrgy === e.detail.value
                    );
                    setbrgydata(regiond[0].brgyname.toString());
                  }}
                >
                  {DataSource3.filter(
                    (DataSource3) => DataSource3.FK_addmuncity === muncity
                  ).map((m) => (
                    <IonSelectOption key={m.PK_addbrgy} value={m.PK_addbrgy}>
                      {m.brgyname}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>No./St./Subd.:</IonLabel>
                <IonInput
                  required
                  type="text"
                  value={addother.toUpperCase()}
                  onInput={(e: any) =>
                    setaddother(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={() => {
                      if (checkadd === 0) {
                        setcahousest(addother);
                        setcabrgy(brgydata);
                        setcamuncity(muncitydata);
                        setcaprovince(provincedata);
                        setrefmodaladdresss(false);
                      } else if (checkadd === 1) {
                        setcwhousest(addother);
                        setcwbrgy(brgydata);
                        setcwmuncity(muncitydata);
                        setcwprovince(provincedata);
                        setrefmodaladdresss(false);
                      } else if (checkadd === 2) {
                        setpachousest(addother);
                        setpacbrgy(brgydata);
                        setpacmuncity(muncitydata);
                        setpacprovince(provincedata);
                        setrefmodaladdresss(false);
                      }
                    }}
                  >
                    Save
                    <IonIcon slot="start" icon={save} />
                  </IonButton>

                  {/* <IonButton
                      className="ion-margin "
                      type="reset"
                      color="success"
                      onClick={() => {
                        setaddother("");
                        setbrgydata("");
                        setmuncitydata("");
                        setprovincedata("");
                      }}
                    >
                      Reset
                      <IonIcon slot="start" icon={refreshCircle} />
                    </IonButton> */}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    );
  }

  function InsertCovidPatient() {
    if (
      datebirth &&
      dateprefered &&
      namelast &&
      namefirst &&
      namemiddle &&
      gender !== ""
    ) {
      fetch(Auth.globalwebhost + "/bookingapp/Files/appregistnew9.1", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isHospRec: isHosprec === true ? "1" : "0",
          dateprefered: dateprefered,
          philhealthno: philhealthnum,
          patientlast: namelast,
          patientfirst: namefirst,
          patientmiddle: namemiddle,
          name_show: "",
          patientsuffix: namesuffix,
          patientbdate: datebirth,
          patientgender: gender,
          civilstatus: civilstatus,
          nationality: nationality,
          occupation: occupation,
          chlb: cahousest,
          csb: cabrgy,
          cmc: camuncity,
          cprovince: caprovince,
          chpno: cahomephoneno,
          cwpno: cacellphoneno,
          coemailadd: caemailadd,
          cwac: "",
          cwaclb: cwhousest,
          cwacb: cwbrgy,
          cwacmc: cwmuncity,
          cwacprovince: cwprovince,
          cwachpno: cwhomephoneno,
          cwacwpno: cwcellphoneno,
          cwacemailadd: cwemailadd,
          caiprecon: caipreconst,
          caidatefconsult: caidatefconsult,
          cainamefaci: cainfacifirst,
          caicaddhf: "0",
          caidateadd: Datenow,
          cainamefacifirst: caicaseadmfname,
          cairegionoffaci: caicaseadmrpf,
          dtrhospichk: dpadmhoschk === true ? "1" : 0,
          dtradmhos: dpadmhos,
          dtrdatehospi: dpadmhosdt,
          dtrisochk: dpadmisochk === true ? "1" : 0,
          dtriso: dpadmiso,
          dtrdateiso: dpadminsodt,
          dtrhomechk: dhhomechk === true ? "1" : 0,
          dtrdatehome: dhhomedt,
          spphcw: sphcwchk,
          sppnamelocfac: sphcw,
          spprofw: spofwchk,
          sppcorigin: spofw,
          spplsi: splsichk,
          spplsiorigin: splsi,
          spplcs: splcschk,
          spplcsyes: splcs,
          spyesnameins: splcsname,
          phlb: pachousest,
          psb: pacbrgy,
          pmc: pacmuncity,
          pprovince: pacprovince,
          pphno: pachomephoneno,
          ppwno: paccellphoneno,
          pemailadd: pacemailadd,
          ohbn: "",
          ostreet: "",
          ocitymuni: "",
          oprovince: "",
          ocountry: "",
          oplaceofwork: "",
          oemployername: "",
          ocphno: "",
          clasymp: clasymp === true ? "1" : 0,
          clfeverchk: clfever === true ? "1" : 0,
          clfever: clfevertext,
          clcough: clcough === true ? "1" : 0,
          clgene: clgene === true ? "1" : 0,
          clfatigue: clfatigue === true ? "1" : 0,
          clheadache: clheadache === true ? "1" : 0,
          clmyalgia: clmyala === true ? "1" : 0,
          clsorethroat: clsoreth === true ? "1" : 0,
          clcoryza: clcoryz === true ? "1" : 0,
          cldysp: cldysp === true ? "1" : 0,
          clanore: clanore === true ? "1" : 0,
          clnausea: clnau === true ? "1" : 0,
          clvomit: clvomit === true ? "1" : 0,
          cldiarrhea: cldiar === true ? "1" : 0,
          clams: clams === true ? "1" : 0,
          clanosmia: clanos === true ? "1" : 0,
          clageusia: clageu === true ? "1" : 0,
          clotherchk: clothers === true ? "1" : 0,
          clotherssspec: clotherstext,
          // comonone: comonone === true ? "1" : 0,
          comonone: comor,
          comohyper: comohyper === true ? "1" : 0,
          comodiab: comodiab === true ? "1" : 0,
          comoheartdi: comoheartd === true ? "1" : 0,
          comolungdi: comolungd === true ? "1" : 0,
          comogastro: comogastro === true ? "1" : 0,
          comogenit: comogenit === true ? "1" : 0,
          comoneuro: comoneuro === true ? "1" : 0,
          comocancer: comocancer === true ? "1" : 0,
          comoother: comoother === true ? "1" : 0,
          comoottext: comoothertext,
          clpregnant: preglmp,
          cllmp: lmp,
          classhighrisk: highrisk,
          exhistory: exhistory,
          exdatecontact: exdatecont,
          explacetrans: explacetrans,
          explacehealfaci: exhfacichk === true ? "1" : 0,
          explacehealfacitext: exhfacidetail,
          explacehealfacidatev: exhfacidatevisit,
          explacejail: excschk === true ? "1" : 0,
          explacejailtext: excsdetail,
          explacejaildatev: excsdate,
          explacemarket: exmarketchk === true ? "1" : 0,
          explacemarkettext: exmarketdetail,
          explacemarketdatev: exmarketdetaildate,
          explacehome: "0",
          explacehometext: "",
          explacehomedatev: Datenow,
          explaceit: "0",
          explaceittext: "",
          explaceitdatev: Datenow,
          explaceschool: exschoolchk === true ? "1" : 0,
          explaceshooltext: exschooldetail,
          explaceschooldatev: exschooldetaildate,
          explacetranspo: extranspochk === true ? "1" : 0,
          explacetranspotext: "",
          explacetranspodatev: Datenow,
          explacework: explacechk === true ? "1" : 0,
          explaceworktext: explacedetail,
          explaceworkdatev: explacedetaildate,
          explacelocaltravel: "0",
          explacelocaltraveltext: "",
          explacelocaltraveldatev: Datenow,
          explacesocial: exsocialgathchk === true ? "1" : 0,
          explacesocialtext: exsocialgathdetail,
          explacesocialdatev: exsocialgathdetaildate,
          explaceothers: exotherchk === true ? "1" : 0,
          exothersehtype: exotherdetail,
          explaceothersdatev: exotherdetaildate,
          trhistory: "0",
          tportcountry: "",
          tasvessel: tronair,
          tfvnumber: tronfnum,
          tdatedep: trondatedept,
          tdatearri: trondatearri,
          tronset: "0",
          tronsetporigin: "",
          tronsetasvessel: "",
          tronsetfvnumber: "",
          tronsetdatedep: Datenow,
          tronsetdatearri: Datenow,
          exname1: exname1,
          excontact1: excontact1,
          exname2: exname2,
          excontact2: excontact2,
          exname3: exname3,
          excontact3: excontact3,
          exname4: exname4,
          excontact4: excontact4,
          outnameinformant: trnameinfor,
          outrelationship: trrelaship,
          outphoneno: trcontact,
          accname1: "",
          acccontact1: "",
          accexposure1: "",
          accname2: "",
          acccontact2: "",
          accexposure2: "",
          accname3: "",
          acccontact3: "",
          accexposure3: "",
          accname4: "",
          acccontact4: "",
          accexposure4: "",
          accname5: "",
          acccontact5: "",
          accexposure5: "",
          accname6: "",
          acccontact6: "",
          accexposure6: "",
          accname7: "",
          acccontact7: "",
          accexposure7: "",
          accname8: "",
          acccontact8: "",
          accexposure8: "",
          accname9: "",
          acccontact9: "",
          accexposure9: "",
          accname10: "",
          acccontact10: "",
          accexposure10: "",
          covidbefore: covidbefore,
          ycovidlab: ycovidlab,
          ydspeci: ydspeci,
          nswabs: nswabs,
          sppfnt: sppfnt,
          sppfntorigin: sppfntorigin,
          typeofservice: tservice,
          typeoftest: ttesttype,
          wclosesetting: wclosesetting,
          spofwyes: spofwyes,
          sppfnttype: sppfnttype,
          spipchk: 0,
          iptext: "",
          ari: ari,
          intcof: intcof,
          intdateof: intdateof,
          intdateto: intdateto,
          inttrans: inttrans,
          exhfaciadd: exhfaciadd,
          exhfacidateto: exhfacidateto,
          exfacitrans: exfacitrans,
          excsadd: excsadd,
          excsdateto: excsdateto,
          excstrans: excstrans,
          exschooladd: exschooladd,
          exschooldateto: exschooldateto,
          exschooltrans: exschooltrans,
          explaceadd: explaceadd,
          explacedateto: explacedateto,
          exexplacetrans: exexplacetrans,
          exmarketadd: exmarketadd,
          exmarketdateto: exmarketdateto,
          exmarkettrans: exmarkettrans,
          exsocialgathadd: exsocialgathadd,
          exsocialgathdateto: exsocialgathdateto,
          exsocialgathtrans: exsocialgathtrans,
          exotheradd: exotheradd,
          exotherdateto: exotherdateto,
          exothertrans: exothertrans,
          extransvessel: extransvessel,
          extransvessel2: extransvessel2,
          extransfvessel: extransfvessel,
          extransfvessel2: extransfvessel2,
          extransorigin: extransorigin,
          extransorigin2: extransorigin2,
          extransdatedep: extransdatedep,
          extransdatedep2: extransdatedep2,
          extransdesti: extransdesti,
          extransdesti2: extransdesti2,
          extransarri: extransarri,
          extransarri2: extransarri2,
          dofill: dofill,
          sppcopassport: copassport,
          sppfntpassport: fntpassport,
          sppnamelocfac1: splocfaci,
          vidate1: datevaci1,
          viname1: namevaci1,
          vidose1: dosevaci1,
          vicenter1: vaccicen1,
          virehf1: regfacivaci1,
          viae1: adversevaci1,
          vidate2: datevaci2,
          viname2: namevaci2,
          vidose2: dosevaci2,
          vicenter2: vaccicen2,
          virehf2: regfacivaci2,
          viae2: adversevaci2,
          typeofpackage: 0,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson);
          if (responseJson.refcovidid !== 0) {
            setrefcovidid(responseJson.refcovidid);
            setrefmodalnumbmod(true);
          }
        })
        .catch((error) => {
          alert("Duplicate Booking");
        });
    } else {
      if (dateprefered === "") {
        alert("Date Preferred is Empty");
      } else if (datebirth === "") {
        alert("Birthday is Empty");
      } else if (gender === "") {
        alert("Gender is Empty");
      } else {
        alert("PLEASE FILL IN THE BLANKS WITH ASTERISK*");
      }
    }
  }

  function test() {
    return (
      <IonAlert
        isOpen={showalert}
        backdropDismiss={false}
        onDidDismiss={() => setshowalert(false)}
        cssClass="my-custom-class"
        header={"Information - Covid Book APP"}
        message={"Are you sure you want to continue"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            // handler: blah => {
            //   console.log('Confirm Cancel: blah');
            // }
          },
          {
            text: "Save",
            handler: () => {
              InsertCovidPatient();
            },
          },
        ]}
      />
    );
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder ">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonCol>
              {/* <IonTitle>SPCMC Booking App</IonTitle> */}
              <IonRow>
                <img
                  src="assets/icon/logowhite.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>

                <IonText className="imgcontainer">
                  COVID-19 Online Registration <br></br>
                  CIF Form Version 9.0
                  {/* {typeholder} */}
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form>
          <IonRow>
            <IonCol>
              <IonCardTitle className="ion-margin-horizontal ion-margin-top ">
                {" "}
                REGISTRATION:
              </IonCardTitle>
              <IonText className="ion-margin-horizontal ion-margin-top">
                {" "}
                Please fill in the blanks with asterisk
                <IonText color="danger">*</IonText>
              </IonText>
            </IonCol>
          </IonRow>
          <IonCard>
            <IonItem>
              <IonIcon slot="end" icon={calendarOutline} />
              <IonLabel>
                {" "}
                <b>
                  Date Preferred for Swabbing<IonText color="danger">*</IonText>
                </b>
              </IonLabel>
              <IonDatetime
                min={Moment(new Date(Datenow)).format("YYYY-MM-DD")}
                displayFormat="YYYY-MM-DD"
                value={dateprefered}
                onIonChange={(e) =>
                  setprefered(
                    Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                  )
                }
              ></IonDatetime>
            </IonItem>

            <IonItem>
              <IonCheckbox
                color="dark"
                checked={isHosprec}
                onIonChange={(e) => setHosprec(e.detail.checked)}
                slot="start"
              ></IonCheckbox>
              <IonText>
                <b> Do you already have a record with us?</b>
              </IonText>
            </IonItem>

            <IonItem>
              <IonCheckbox
                color="dark"
                checked={isemployee}
                onIonChange={(e) => setisemployee(e.detail.checked)}
                onClick={(e) => {
                  if (isemployee === true) {
                    setcwhousest("MAHARLIKA HWAY");
                    setcwbrgy("SAN RAFAEL");
                    setcwmuncity("SAN PABLO CITY");
                    setcwprovince("LAGUNA");
                    setcwhomephoneno("5620726");
                    setcwemailadd("SPCMEDICALCENTER@GMAIL.COM");
                    setsphcwchk("1");
                    setsphcw("SAN PABLO COLLEGES MEDICAL CENTER");
                    setsplocfaci("BRGY. SAN RAFAEL, SAN PABLO CITY");
                    setsigsymp("1");
                    setexhistory("1");
                    setexdatecont(
                      Moment(new Date(dateprefered))
                        .subtract(5, "days")
                        .toString()
                    );
                    setexplacetrans("4");
                    setexhfacichk(true);
                    setexhfacidetail("SAN PABLO COLLEGES MEDICAL CENTER");
                    setexhfaciadd("BRGY. SAN RAFAEL SAN PABLO CITY");

                    setexplacechk(true);
                    setexplacedetail("SAN PABLO COLLEGES MEDICAL CENTER");
                    setexplaceadd("BRGY. SAN RAFAEL SAN PABLO CITY");
                  } else {
                    setcwhousest("");
                    setcwbrgy("");
                    setcwmuncity("");
                    setcwprovince("");
                    setcwhomephoneno("");
                    setcwemailadd("");
                    setsphcwchk("");
                    setsphcw("");
                    setsplocfaci("");
                    setsigsymp("0");
                    setexhistory("2");
                    setexdatecont(
                      Moment(new Date(exdatecont)).format("YYYY-MM-DD")
                    );
                    setexplacetrans("2");
                    setexhfacichk(false);
                    setexhfacidetail("");
                    setexhfaciadd("");

                    setexplacechk(false);
                    setexplacedetail("");
                    setexplaceadd("");
                  }
                }}
                slot="start"
              ></IonCheckbox>
              <IonText>
                <b>
                  {" "}
                  Are you an Employee of San Pablo Colleges Medical Center?
                </b>
              </IonText>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Name of Informant <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trnameinfor}
                onInput={(e: any) => settrnameinfor(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Relationship <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trrelaship}
                onInput={(e: any) => settrrelaship(e.target.value)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Contact No <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trcontact}
                onInput={(e: any) => settrcontact(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItemGroup>
              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  PART 1: Patient Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonLabel>
                  <b>Please Select a Type of Service?</b>
                </IonLabel>
                <IonSelect
                  value={tservice}
                  onIonChange={(e) => settservice(e.detail.value)}
                >
                  <IonSelectOption value="0">Laboratory</IonSelectOption>
                  <IonSelectOption value="1">Home Service</IonSelectOption>
                  <IonSelectOption value="2">Drive-Thru</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonIcon slot="end" icon={copyOutline} />
                <IonLabel>
                  <b>Type of Test</b>
                </IonLabel>
                <IonSelect
                  value={ttesttype}
                  onIonChange={(e) => setttesttype(e.detail.value)}
                >
                  <IonSelectOption value="0">RTPCR</IonSelectOption>
                  <IonSelectOption value="1">Rapid Antigen</IonSelectOption>
                </IonSelect>
              </IonItem>

              {/* <IonItem hidden={Boolean(ttesttype === "0" ? false : true)}>
                <IonIcon slot="end" icon={alarmOutline} />
                <IonLabel>
                  <b>Type of Package</b>
                </IonLabel>
                <IonSelect
                  value={ttestPackage}
                  onIonChange={(e) => setttestPackage(e.detail.value)}
                >
                  <IonSelectOption value="2">48 Hours</IonSelectOption>
                  <IonSelectOption value="1">24 Hours</IonSelectOption>
                </IonSelect>
              </IonItem> */}

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.1. Patient Profile
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Last Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  // autoCapitalize="on"
                  required
                  type="text"
                  value={namelast.toUpperCase()}
                  onInput={(e: any) =>
                    setnamelast(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  First Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={namefirst.toUpperCase()}
                  onInput={(e: any) =>
                    setnamefirst(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonCol>
                  <IonRow>
                    <IonLabel position="stacked">
                      {" "}
                      Middle Name <IonText color="danger">*</IonText>
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      value={namemiddle.toUpperCase()}
                      onInput={(e: any) =>
                        setnamemiddle(e.target.value.toUpperCase())
                      }
                      clearInput
                    ></IonInput>

                    <IonLabel position="stacked"> Name Suffix</IonLabel>
                    <IonInput
                      required
                      type="text"
                      value={namesuffix.toUpperCase()}
                      onInput={(e: any) =>
                        setnamesuffix(e.target.value.toUpperCase())
                      }
                      clearInput
                    ></IonInput>
                  </IonRow>
                </IonCol>
              </IonItem>

              <IonItem>
                <IonIcon slot="end" icon={calendarOutline} />
                <IonLabel>
                  <b>Birth Day</b>
                </IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={datebirth}
                  onIonChange={(e) =>
                    setdatebirth(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>
              <IonItem>
                <IonIcon slot="end" icon={manOutline} />
                <IonLabel>
                  <b>Gender</b>
                </IonLabel>
                <IonSelect
                  value={gender}
                  onIonChange={(e) => setgender(e.detail.value)}
                >
                  <IonSelectOption value="1">Male</IonSelectOption>
                  <IonSelectOption value="0">Female</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Civil Status <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={civilstatus.toUpperCase()}
                  onInput={(e: any) =>
                    setcivilstatus(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Nationality <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={nationality.toUpperCase()}
                  onInput={(e: any) =>
                    setnationality(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Occupation <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={occupation.toUpperCase()}
                  onInput={(e: any) =>
                    setoccupation(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Philhealth No <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  // autoCapitalize="on"
                  required
                  type="text"
                  value={philhealthnum.toUpperCase()}
                  onInput={(e: any) =>
                    setphilhealthnum(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonText className="ion-margin">
                  Work in a Closed Setting?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={wclosesetting}
                onIonChange={(e) => setwclosesetting(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>Unknown</IonLabel>
                    <IonRadio slot="start" value="2" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItemDivider>
                <IonText>
                  {" "}
                  <h3>
                    {" "}
                    Click <IonIcon slot="end" icon={addCircleSharp} /> to
                    auto-select the address in PART 1.2, 1.3 and 1.4{" "}
                  </h3>{" "}
                </IonText>
              </IonItemDivider>
              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.2. Current Address in the Philippines and Contact
                  Information* (Provide address of institution if patient lives
                  in closed settings, see 1.5)
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>

              <IonText className="ion-margin-vertical"></IonText>
              <IonItemDivider>
                <IonText>
                  <b>Please Fill in the address</b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="stacked"></IonLabel>

                <IonIcon
                  slot="start"
                  icon={addCircleSharp}
                  onClick={() => {
                    setrefmodaladdresss(true);
                    setcheckadd(0);
                  }}
                />

                <IonInput
                  placeholder="House No./Lot/Bldg and Street:"
                  value={cahousest.toUpperCase()}
                  onInput={(e: any) =>
                    setcahousest(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Barangay:"
                  value={cabrgy.toUpperCase()}
                  onInput={(e: any) => setcabrgy(e.target.value.toUpperCase())}
                ></IonInput>

                <IonInput
                  placeholder="Municipality/City"
                  value={camuncity.toUpperCase()}
                  onInput={(e: any) =>
                    setcamuncity(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Province"
                  value={caprovince.toUpperCase()}
                  onInput={(e: any) =>
                    setcaprovince(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Home Phone No:"
                  value={cahomephoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setcahomephoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Cellphone No:"
                  value={cacellphoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setcacellphoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="E-mail Address:"
                  value={caemailadd}
                  onInput={(e: any) => setcaemailadd(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.3. Permanent Address and Contact Information (If different
                  from current address)<IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>
              <IonItemDivider>
                <IonText>
                  <b>
                    Please Fill in the address if different from current address
                  </b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="stacked"></IonLabel>

                <IonIcon
                  slot="start"
                  icon={addCircleSharp}
                  onClick={() => {
                    setrefmodaladdresss(true);
                    setcheckadd(2);
                  }}
                />

                <IonInput
                  placeholder="House No./Lot/Bldg and Street:"
                  value={pachousest.toUpperCase()}
                  onInput={(e: any) =>
                    setpachousest(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Barangay:"
                  value={pacbrgy.toUpperCase()}
                  onInput={(e: any) => setpacbrgy(e.target.value.toUpperCase())}
                ></IonInput>
                <IonInput
                  placeholder="Municipality/City"
                  value={pacmuncity.toUpperCase()}
                  onInput={(e: any) =>
                    setpacmuncity(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Province"
                  value={pacprovince.toUpperCase()}
                  onInput={(e: any) =>
                    setpacprovince(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Home Phone No:"
                  value={pachomephoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setpachomephoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Cellphone No:"
                  value={paccellphoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setpaccellphoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="E-mail Address:"
                  value={pacemailadd}
                  onInput={(e: any) => setpacemailadd(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.4. Current Workplace Address and Contact Information
                </IonText>
              </IonItemDivider>
              <IonItem>
                <IonLabel position="stacked"></IonLabel>
                <IonIcon
                  slot="start"
                  icon={addCircleSharp}
                  onClick={() => {
                    setrefmodaladdresss(true);
                    setcheckadd(1);
                  }}
                />

                <IonInput
                  placeholder="House No./Lot/Bldg and Street:"
                  value={cwhousest.toUpperCase()}
                  onInput={(e: any) =>
                    setcwhousest(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Barangay:"
                  value={cwbrgy.toUpperCase()}
                  onInput={(e: any) => setcwbrgy(e.target.value.toUpperCase())}
                ></IonInput>
                <IonInput
                  placeholder="Municipality/City"
                  value={cwmuncity.toUpperCase()}
                  onInput={(e: any) =>
                    setcwmuncity(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Province"
                  value={cwprovince.toUpperCase()}
                  onInput={(e: any) =>
                    setcwprovince(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Home Phone No:"
                  value={cwhomephoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setcwhomephoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Cellphone No:"
                  value={cwcellphoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setcwcellphoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="E-mail Address:"
                  value={cwemailadd}
                  onInput={(e: any) => setcwemailadd(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.5. Special Population
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText className="ion-margin">
                  {" "}
                  Are you a Health Care Worker?*
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={sphcwchk}
                onIonChange={(e) => setsphcwchk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(sphcwchk === "1" ? false : true)}>
                <IonInput
                  placeholder="Yes, Name of health facility"
                  onInput={(e: any) => setsphcw(e.target.value.toUpperCase())}
                  value={sphcw.toUpperCase()}
                ></IonInput>

                <IonInput
                  placeholder=" and Location of health facility"
                  onInput={(e: any) =>
                    setsplocfaci(e.target.value.toUpperCase())
                  }
                  value={splocfaci.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">
                  Are you a Returning Overseas Filipino Worker*
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={spofwchk}
                onIonChange={(e) => setspofwchk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(spofwchk === "1" ? false : true)}>
                <IonInput
                  placeholder="Yes, Country of origin"
                  onInput={(e: any) => setspofw(e.target.value.toUpperCase())}
                  value={spofw.toUpperCase()}
                ></IonInput>

                <IonInput
                  placeholder="and Passport Number"
                  onInput={(e: any) =>
                    setcopassport(e.target.value.toUpperCase())
                  }
                  value={copassport.toUpperCase()}
                ></IonInput>

                <IonRadioGroup
                  value={spofwyes}
                  onIonChange={(e) => setspofwyes(e.detail.value)}
                >
                  <IonItem lines="none">
                    <IonLabel>OFW</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>NON-OFW</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRadioGroup>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">
                  Are you a Foreign National Traveler?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={sppfnt}
                onIonChange={(e) => setsppfnt(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(sppfnt === "1" ? false : true)}>
                <IonInput
                  placeholder="Yes, Country of origin"
                  onInput={(e: any) =>
                    setsppfntorigin(e.target.value.toUpperCase())
                  }
                  value={sppfntorigin.toUpperCase()}
                ></IonInput>

                <IonInput
                  placeholder="and Passport Number"
                  onInput={(e: any) =>
                    setfntpassport(e.target.value.toUpperCase())
                  }
                  value={fntpassport.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">
                  Locally Stranded Individual
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={splsichk}
                onIonChange={(e) => setsplsichk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(splsichk === "1" ? false : true)}>
                <IonInput
                  placeholder="Yes, City, Mun, and Prov of origin"
                  onInput={(e: any) => setsplsi(e.target.value.toUpperCase())}
                  value={splsi.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItem hidden={Boolean(splsichk === "1" ? false : true)}>
                <IonRadioGroup
                  value={sppfnttype}
                  onIonChange={(e) => setsppfnttype(e.detail.value)}
                >
                  <IonRow>
                    <IonItem lines="none">
                      <IonLabel>Locally Stranded Individual</IonLabel>
                      <IonRadio slot="start" value={"1"} color="dark" />
                    </IonItem>

                    <IonItem lines="none">
                      <IonLabel>
                        Authorized Person Outside Residence / Local Traveler
                      </IonLabel>
                      <IonRadio slot="start" value={"0"} color="dark" />
                    </IonItem>
                  </IonRow>
                </IonRadioGroup>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">
                  {" "}
                  Are you Living in a closed setting?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={splcschk}
                onIonChange={(e) => setsplcschk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(splcschk === "1" ? false : true)}>
                <IonInput
                  placeholder="If Yes, specify Type of Institution"
                  onInput={(e: any) => setsplcs(e.target.value.toUpperCase())}
                  value={splcs.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItem hidden={Boolean(splcschk === "1" ? false : true)}>
                <IonInput
                  placeholder="Specify Name of Institution"
                  onInput={(e: any) =>
                    setsplcsname(e.target.value.toUpperCase())
                  }
                  value={splcsname.toUpperCase()}
                ></IonInput>
              </IonItem>

              {/* <IonItem>
                <IonText className="ion-margin">
                  {" "}
                  Are you a Indigenous Person*?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={spipchk}
                onIonChange={(e) => setspipchk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup> */}
              {/* 
              <IonItem hidden={Boolean(spipchk === "1" ? false : true)}>
                <IonInput
                  placeholder="If Yes, specify group"
                  onInput={(e: any) => setiptext(e.target.value.toUpperCase())}
                  value={iptext.toUpperCase()}
                ></IonInput>
              </IonItem> */}

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  PART 2. Case Investigation Details
                </IonText>
              </IonItemDivider>
              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  2.1. Consultation and Admission Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText className="ion-margin">
                  Did you have previous COVID-19 related consultation?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={caipreconst}
                onIonChange={(e) => setcaipreconst(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(caipreconst === "1" ? false : true)}>
                <IonLabel>Date of First Consultation</IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={caidatefconsult}
                  onIonChange={(e) =>
                    setcaidatefconsult(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>
              <IonItem hidden={Boolean(caipreconst === "1" ? false : true)}>
                <IonInput
                  placeholder="Name of Facility where first consult was done"
                  onInput={(e: any) =>
                    setcainfacifirst(e.target.value.toUpperCase())
                  }
                  value={cainfacifirst.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItem hidden={Boolean(caipreconst === "1" ? false : true)}>
                <IonInput
                  placeholder="Name of Facility where patient was first admitted"
                  onInput={(e: any) =>
                    setcaicaseadmfname(e.target.value.toUpperCase())
                  }
                  value={caicaseadmfname.toUpperCase()}
                ></IonInput>
              </IonItem>
              <IonItem hidden={Boolean(caipreconst === "1" ? false : true)}>
                <IonInput
                  placeholder="Region and Province of Facility"
                  onInput={(e: any) =>
                    setcaicaseadmrpf(e.target.value.toUpperCase())
                  }
                  value={caicaseadmrpf.toUpperCase()}
                ></IonInput>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  2.2. Disposition at Time of Report* (Provide name of
                  hospital/isolation/quarantine facility)
                </IonText>
              </IonItemDivider>

              {/* FIRST  */}
              <IonItem>
                <IonCheckbox
                  slot="start"
                  color="light"
                  checked={dpadmhoschk}
                  onIonChange={(e) =>
                    setdpadmhoschk((dpadmhoschk) => !dpadmhoschk)
                  }
                />
                <IonLabel>Are you admitted in a hospital?</IonLabel>
              </IonItem>

              <IonItem hidden={Boolean(!dpadmhoschk)}>
                <IonInput
                  placeholder="Name of Hospital"
                  value={dpadmhos}
                  onInput={(e: any) => setdpadmhos(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItem hidden={Boolean(!dpadmhoschk)}>
                <IonLabel>Date and time admitted in hospital</IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={dpadmhosdt}
                  onIonChange={(e) =>
                    setdpadmhosdt(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>

              {/* SECOND  */}
              <IonItem>
                <IonCheckbox
                  slot="start"
                  color="light"
                  checked={dpadmisochk}
                  onIonChange={(e) =>
                    setdpadmisochk((dpadmisochk) => !dpadmisochk)
                  }
                />
                <IonLabel>
                  Are you in an isolation /quarantine facility?
                </IonLabel>
              </IonItem>

              <IonItem hidden={Boolean(!dpadmisochk)}>
                <IonInput
                  placeholder="Name of Isolation/Quarantine Facility"
                  value={dpadmiso}
                  onInput={(e: any) => setdpadmiso(e.target.value)}
                ></IonInput>
              </IonItem>

              <IonItem hidden={Boolean(!dpadmisochk)}>
                <IonLabel>
                  Date and time isolation/quarantine in facility
                </IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={dpadminsodt}
                  onIonChange={(e) =>
                    setdpadminsodt(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>

              {/* THRID  */}
              <IonItem>
                <IonCheckbox
                  slot="start"
                  color="light"
                  checked={dhhomechk}
                  onIonChange={(e) => setdhhomechk((dhhomechk) => !dhhomechk)}
                />
                <IonLabel>Are you under home quarantine? </IonLabel>
              </IonItem>

              <IonItem hidden={Boolean(!dhhomechk)}>
                <IonLabel>Date and time isolation/quarantine at home</IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={dhhomedt}
                  onIonChange={(e) =>
                    setdhhomedt(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  2.5. Vaccination Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText>Are you already Vaccinated?</IonText>
              </IonItem>
              <IonItemDivider>
                <IonText>
                  <b>
                    {" "}
                    - If you an employee of San Pablo Colleges Medical Center,
                    you must fill out the vaccination details.
                  </b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>

              <IonRadioGroup
                value={vaciyes}
                onIonChange={(e) => setvaciyes(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value={true} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={false} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(!vaciyes)}>
                <IonLabel position="stacked">
                  {" "}
                  Date of Vaccination <IonText color="danger">*</IonText>
                </IonLabel>
                <IonDatetime
                  placeholder="1."
                  displayFormat="YYYY-MM-DD"
                  value={datevaci1}
                  onIonChange={(e) =>
                    setdatevaci1(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
                <IonDatetime
                  placeholder="2."
                  displayFormat="YYYY-MM-DD"
                  value={datevaci2}
                  onIonChange={(e) =>
                    setdatevaci2(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>

                <IonLabel position="stacked">
                  {" "}
                  Name of Vaccination <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  placeholder="1."
                  value={namevaci1}
                  onInput={(e: any) => setnamevaci1(e.target.value)}
                ></IonInput>
                <IonInput
                  placeholder="2."
                  value={namevaci2}
                  onInput={(e: any) => setnamevaci2(e.target.value)}
                ></IonInput>

                <IonLabel position="stacked">
                  {" "}
                  Dose number (e.g 1st, 2nd) <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  placeholder="1."
                  value={dosevaci1}
                  onInput={(e: any) => setdosevaci1(e.target.value)}
                ></IonInput>
                <IonInput
                  placeholder="2."
                  value={dosevaci2}
                  onInput={(e: any) => setdosevaci2(e.target.value)}
                ></IonInput>

                <IonLabel position="stacked">
                  {" "}
                  Vaccination Center / facility{" "}
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  placeholder="1."
                  value={vaccicen1}
                  onInput={(e: any) => setvaccicen1(e.target.value)}
                ></IonInput>
                <IonInput
                  placeholder="2."
                  value={vaccicen2}
                  onInput={(e: any) => setvaccicen2(e.target.value)}
                ></IonInput>

                <IonLabel position="stacked">
                  {" "}
                  Region of health / facility{" "}
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  placeholder="1."
                  value={regfacivaci1}
                  onInput={(e: any) => setregfacivaci1(e.target.value)}
                ></IonInput>
                <IonInput
                  placeholder="2."
                  value={regfacivaci2}
                  onInput={(e: any) => setregfacivaci2(e.target.value)}
                ></IonInput>

                <IonText>1. Adverse event/s?</IonText>

                <IonRadioGroup
                  value={adversevaci1}
                  onIonChange={(e) => setadversevaci1(e.detail.value)}
                >
                  <IonRow>
                    <IonItem lines="none">
                      <IonLabel>Yes</IonLabel>
                      <IonRadio slot="start" value="1" color="dark" />
                    </IonItem>

                    <IonItem lines="none">
                      <IonLabel>No</IonLabel>
                      <IonRadio slot="start" value="0" color="dark" />
                    </IonItem>
                  </IonRow>
                </IonRadioGroup>

                <IonText>2. Adverse event/s?</IonText>

                <IonRadioGroup
                  value={adversevaci2}
                  onIonChange={(e) => setadversevaci2(e.detail.value)}
                >
                  <IonRow>
                    <IonItem lines="none">
                      <IonLabel>Yes</IonLabel>
                      <IonRadio slot="start" value="1" color="dark" />
                    </IonItem>

                    <IonItem lines="none">
                      <IonLabel>No</IonLabel>
                      <IonRadio slot="start" value="0" color="dark" />
                    </IonItem>
                  </IonRow>
                </IonRadioGroup>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  2.6. Clinical Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText>Signs and Symptoms</IonText>
              </IonItem>

              <IonRadioGroup
                value={sigsymp}
                onIonChange={(e) => setsigsymp(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
              <IonCol hidden={Boolean(sigsymp === "1" ? false : true)}>
                <IonItem>
                  <IonLabel>Date of Onset of illness</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={dofill}
                    onIonChange={(e) =>
                      setdofill(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clasymp}
                    onIonChange={(e) => setclasymp((clasymp) => !clasymp)}
                  />
                  <IonLabel>Asymptomatic</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clfever}
                    onIonChange={(e) => setclfever((clfever) => !clfever)}
                  />
                  <IonLabel>Fever</IonLabel>

                  <IonInput
                    hidden={Boolean(!clfever)}
                    placeholder="Enter Temperature"
                    value={clfevertext}
                    onInput={(e: any) => setclfevertext(e.target.value)}
                    slot="end"
                  ></IonInput>
                  <IonText hidden={Boolean(!clfever)} slot="end">
                    °C
                  </IonText>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clcough}
                    onIonChange={(e) => setclcough((clcough) => !clcough)}
                  />
                  <IonLabel>Cough</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clgene}
                    onIonChange={(e) => setclgene((clgene) => !clgene)}
                  />
                  <IonLabel>General Weakness</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clfatigue}
                    onIonChange={(e) => setclfatigue((clfatigue) => !clfatigue)}
                  />
                  <IonLabel>Fatigue</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clheadache}
                    onIonChange={(e) =>
                      setclheadache((clheadache) => !clheadache)
                    }
                  />
                  <IonLabel>Headache</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clmyala}
                    onIonChange={(e) => setclmyala((clmyala) => !clmyala)}
                  />
                  <IonLabel>Myalgia</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clsoreth}
                    onIonChange={(e) => setsoreth((clsoreth) => !clsoreth)}
                  />
                  <IonLabel>Sore Throat</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clcoryz}
                    onIonChange={(e) => setcloryz((clcoryz) => !clcoryz)}
                  />
                  <IonLabel>Coryza</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={cldysp}
                    onIonChange={(e) => setcldysp((cldysp) => !cldysp)}
                  />
                  <IonLabel>Dyspnea</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clanore}
                    onIonChange={(e) => setclanore((clanore) => !clanore)}
                  />
                  <IonLabel>Anorexia</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clnau}
                    onIonChange={(e) => setclnau((clnau) => !clnau)}
                  />
                  <IonLabel>Nausea</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clvomit}
                    onIonChange={(e) => setclvomit((clvomit) => !clvomit)}
                  />
                  <IonLabel>Vomiting</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={cldiar}
                    onIonChange={(e) => setcldiar((cldiar) => !cldiar)}
                  />
                  <IonLabel>Diarrhea</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clams}
                    onIonChange={(e) => setclams((clams) => !clams)}
                  />
                  <IonLabel>Alterned Mental Status</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clanos}
                    onIonChange={(e) => setclanos((clanos) => !clanos)}
                  />
                  <IonLabel>Anosmia (loss of smell)</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clageu}
                    onIonChange={(e) => setclague((clageu) => !clageu)}
                  />
                  <IonLabel>Ageusia (loss of taste )</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={clothers}
                    onIonChange={(e) => setclothers((clothers) => !clothers)}
                  />
                  <IonLabel>Others</IonLabel>

                  <IonInput
                    hidden={Boolean(!clothers)}
                    placeholder="Others"
                    value={clotherstext}
                    onInput={(e: any) => setclotherstext(e.target.value)}
                    slot="end"
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonItem>
                <IonText>Comorbidities</IonText>
              </IonItem>
              <IonRadioGroup
                value={comor}
                onIonChange={(e) => setcomor(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonCol hidden={Boolean(comor === "1" ? false : true)}>
                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comonone}
                    onIonChange={(e) => setcomonone((comonone) => !comonone)}
                  />
                  <IonLabel>None</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comohyper}
                    onIonChange={(e) => setcomohyper((comohyper) => !comohyper)}
                  />
                  <IonLabel>Hypertension</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comodiab}
                    onIonChange={(e) => setcomodiab((comodiab) => !comodiab)}
                  />
                  <IonLabel>Diabetes</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comoheartd}
                    onIonChange={(e) =>
                      setcomohearted((comoheartd) => !comoheartd)
                    }
                  />
                  <IonLabel>Heart Disease</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comolungd}
                    onIonChange={(e) => setcomolungd((comolungd) => !comolungd)}
                  />
                  <IonLabel>Lung Disease</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comogastro}
                    onIonChange={(e) =>
                      setcomogastro((comogastro) => !comogastro)
                    }
                  />
                  <IonLabel>Gastrointestinal</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comogenit}
                    onIonChange={(e) => setcomogenit((comogenit) => !comogenit)}
                  />
                  <IonLabel>Genito-urinary</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comoneuro}
                    onIonChange={(e) => setcomoneuro((comoneuro) => !comoneuro)}
                  />
                  <IonLabel>Neurological Disease</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comocancer}
                    onIonChange={(e) =>
                      setcomocancer((comocancer) => !comocancer)
                    }
                  />
                  <IonLabel>Cancer</IonLabel>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={comoother}
                    onIonChange={(e) => setcomoother((comoother) => !comoother)}
                  />
                  <IonLabel>Others</IonLabel>

                  <IonInput
                    hidden={Boolean(!comoother)}
                    placeholder="Others Symptoms"
                    value={comoothertext}
                    onInput={(e: any) => setcomoothertext(e.target.value)}
                    slot="end"
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonItem>
                <IonText className="ion-margin">Are you pregnant?</IonText>
              </IonItem>

              <IonRadioGroup
                value={preglmp}
                onIonChange={(e) => setpreglmp(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
              <IonItem hidden={Boolean(preglmp === "1" ? false : true)}>
                <IonLabel>Yes, LMP (MM/DD/YYYY)</IonLabel>

                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={lmp}
                  onIonChange={(e) =>
                    setlmp(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">High-risk</IonText>
              </IonItem>

              <IonRadioGroup
                value={highrisk}
                onIonChange={(e) => sethighrisk(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem>
                <IonText className="ion-margin">
                  Was diagnose to have Severe Acute Respiratory illness?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={ari}
                onIonChange={(e) => setari(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="0" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  2.7. Laboratory Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText className="ion-margin">
                  {" "}
                  Have you ever tested positive using RT-PCR before?
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={covidbefore}
                onIonChange={(e) => setcovidbefore(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value={"1"} color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value={"0"} color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
              <IonItem hidden={Boolean(covidbefore === "1" ? false : true)}>
                <IonLabel>Yes, Date of Specimen Collection</IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={ydspeci}
                  onIonChange={(e) =>
                    setydspeci(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>
              <IonItem hidden={Boolean(covidbefore === "1" ? false : true)}>
                <IonInput
                  placeholder="If Yes, Laboratory"
                  onInput={(e: any) =>
                    setycovidlab(e.target.value.toUpperCase())
                  }
                  value={ycovidlab.toUpperCase()}
                ></IonInput>
              </IonItem>
              <IonItem hidden={Boolean(covidbefore === "1" ? false : true)}>
                <IonInput
                  placeholder="Number of previous RT-PCR swabs done"
                  onInput={(e: any) => setnswabs(e.target.value.toUpperCase())}
                  value={nswabs.toUpperCase()}
                  type="number"
                ></IonInput>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  PART 3. Contact Tracing: Exposure and Travel History
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonText className="ion-margin">
                  History of exposure to known probable and/or confirmed
                  COVID-19 case 14 days before the onset of signs and symptoms?
                  OR If Asymptomatic, 14 days before swabbing or specimen
                  collection? *
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={exhistory}
                onIonChange={(e) => setexhistory(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>YES</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="2" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>Unknown</IonLabel>
                    <IonRadio slot="start" value="3" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>

              <IonItem hidden={Boolean(exhistory === "1" ? false : true)}>
                <IonLabel>Yes: Date of LAST Contact</IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={exdatecont}
                  onIonChange={(e) =>
                    setexdatecont(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>

              <IonItem>
                <IonText className="ion-margin">
                  Has the patient been in a place with a known COVID-19
                  transmission 14 days before the onset of signs and symptoms?
                  OR If Asymptomatic, 14 days before swabbing or specimen
                  collection? *
                </IonText>
              </IonItem>

              <IonRadioGroup
                value={explacetrans}
                onIonChange={(e) => setexplacetrans(e.detail.value)}
              >
                <IonRow>
                  <IonItem lines="none">
                    <IonLabel>Yes, Internatinal</IonLabel>
                    <IonRadio slot="start" value="1" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>No</IonLabel>
                    <IonRadio slot="start" value="2" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>Yes, Local</IonLabel>
                    <IonRadio slot="start" value="4" color="dark" />
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel>Unknown Exposure</IonLabel>
                    <IonRadio slot="start" value="3" color="dark" />
                  </IonItem>
                </IonRow>
              </IonRadioGroup>
              <IonCol hidden={Boolean(explacetrans === "1" ? false : true)}>
                <IonItem>
                  <IonLabel>Country of Origin</IonLabel>
                  <IonInput
                    value={intcof.toUpperCase()}
                    onInput={(e: any) =>
                      setintcof(e.target.value.toUpperCase())
                    }
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={intdateof}
                    onIonChange={(e) =>
                      setintdateof(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={intdateto}
                    onIonChange={(e) =>
                      setintdateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem>
                  <IonText className="ion-margin">
                    With ongoing COVID-19 community transmission?
                  </IonText>
                </IonItem>
                <IonRadioGroup
                  value={inttrans}
                  onIonChange={(e) => setinttrans(e.detail.value)}
                >
                  <IonRow>
                    <IonItem lines="none">
                      <IonLabel>YES</IonLabel>
                      <IonRadio slot="start" value="1" color="dark" />
                    </IonItem>

                    <IonItem lines="none">
                      <IonLabel>No</IonLabel>
                      <IonRadio slot="start" value="0" color="dark" />
                    </IonItem>
                  </IonRow>
                </IonRadioGroup>

                <IonItem>
                  <IonInput
                    placeholder="Airline/Sea vessel"
                    value={tronair.toUpperCase()}
                    onInput={(e: any) =>
                      settronair(e.target.value.toUpperCase())
                    }
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    placeholder="Flight/Vessel Number"
                    value={tronfnum.toUpperCase()}
                    onInput={(e: any) =>
                      settronfnum(e.target.value.toUpperCase())
                    }
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonText>Date of Departure</IonText>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={trondatedept}
                    onIonChange={(e) =>
                      settrondatedept(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>
                <IonItem>
                  <IonText>Date of Arrival in PH</IonText>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={trondatearri}
                    onIonChange={(e) =>
                      settrondatearri(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>
              </IonCol>

              <IonCol hidden={Boolean(explacetrans === "4" ? false : true)}>
                <IonItem>
                  <IonText>
                    If Local Travel, specify travel places (Check all that
                    apply, provide name of facility, address, and inclusive
                    travel dates in MM/DD/YYYY)
                  </IonText>
                </IonItem>
                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={exhfacichk}
                    onClick={(e) => setexhfacichk((exhfacichk) => !exhfacichk)}
                    // onIonChange={(e) =>

                    // }
                  />
                  <IonLabel>Health Facility</IonLabel>
                </IonItem>
                <IonItem hidden={!exhfacichk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exhfacidetail}
                    onInput={(e: any) => setexhfacidetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exhfacichk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exhfaciadd}
                    onInput={(e: any) => setexhfaciadd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exhfacichk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exhfacidatevisit}
                    onIonChange={(e) =>
                      setexhfacidatevisit(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exhfacichk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exhfacidateto}
                    onIonChange={(e) =>
                      setexhfacidateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exhfacichk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exfacitrans}
                    onIonChange={(e) => setexfacitrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={excschk}
                    onIonChange={(e) => setexcschk((excschk) => !excschk)}
                  />
                  <IonLabel>Closed Settings (e.g. Jail)</IonLabel>
                </IonItem>
                <IonItem hidden={!excschk}>
                  <IonLabel>Name of Place </IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={excsdetail}
                    onInput={(e: any) => setexcsdetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!excschk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={excsadd}
                    onInput={(e: any) => setexcsadd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!excschk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={excsdate}
                    onIonChange={(e) =>
                      setexcsdate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!excschk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={excsdateto}
                    onIonChange={(e) =>
                      setexcsdateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>
                <IonItem hidden={!excschk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={excstrans}
                    onIonChange={(e) => setexcstrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={exschoolchk}
                    onIonChange={(e) =>
                      setexschoolchk((exschoolchk) => !exschoolchk)
                    }
                  />
                  <IonLabel>School</IonLabel>
                </IonItem>

                <IonItem hidden={!exschoolchk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exschooldetail}
                    onInput={(e: any) => setexschooldetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exschoolchk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exschooladd}
                    onInput={(e: any) => setexschooladd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exschoolchk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exschooldetaildate}
                    onIonChange={(e) =>
                      setexschooldetaildate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exschoolchk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exschooldateto}
                    onIonChange={(e) =>
                      setexschooldateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exschoolchk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exschooltrans}
                    onIonChange={(e) => setexschooltrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={explacechk}
                    onClick={(e) => setexplacechk((explacechk) => !explacechk)}
                  />
                  <IonLabel>Workplace</IonLabel>
                </IonItem>
                <IonItem hidden={!explacechk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={explacedetail}
                    onInput={(e: any) => setexplacedetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!explacechk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={explaceadd}
                    onInput={(e: any) => setexplaceadd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!explacechk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={explacedetaildate}
                    onIonChange={(e) =>
                      setexplacedetaildate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!explacechk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={explacedateto}
                    onIonChange={(e) =>
                      setexplacedateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!explacechk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exexplacetrans}
                    onIonChange={(e) => setexexplacetrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={exmarketchk}
                    onIonChange={(e) =>
                      setexmarketchk((exmarketchk) => !exmarketchk)
                    }
                  />
                  <IonLabel>Market</IonLabel>
                </IonItem>
                <IonItem hidden={!exmarketchk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exmarketdetail}
                    onInput={(e: any) => setexmarketdetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exmarketchk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exmarketadd}
                    onInput={(e: any) => setexmarketadd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exmarketchk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exmarketdetaildate}
                    onIonChange={(e) =>
                      setexmarketdetaildate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exmarketchk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exmarketdateto}
                    onIonChange={(e) =>
                      setexmarketdateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exmarketchk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exmarkettrans}
                    onIonChange={(e) => setexmarkettrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={exsocialgathchk}
                    onIonChange={(e) =>
                      setexsocialgathchk((exsocialgathchk) => !exsocialgathchk)
                    }
                  />
                  <IonLabel>Social Gathering </IonLabel>
                </IonItem>
                <IonItem hidden={!exsocialgathchk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exsocialgathdetail}
                    onInput={(e: any) => setexsocialgathdetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exsocialgathchk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exsocialgathadd}
                    onInput={(e: any) => setexsocialgathadd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exsocialgathchk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exsocialgathdetaildate}
                    onIonChange={(e) =>
                      setexsocialgathdetaildate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exsocialgathchk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exsocialgathdateto}
                    onIonChange={(e) =>
                      setexsocialgathdateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exsocialgathchk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exsocialgathtrans}
                    onIonChange={(e) => setexsocialgathtrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={exotherchk}
                    onIonChange={(e) =>
                      setexotherchk((exotherchk) => !exotherchk)
                    }
                  />
                  <IonLabel>Others </IonLabel>
                </IonItem>
                <IonItem hidden={!exotherchk}>
                  <IonLabel>Name of Place</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exotherdetail}
                    onInput={(e: any) => setexotherdetail(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exotherchk}>
                  <IonLabel>Address</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={exotheradd}
                    onInput={(e: any) => setexotheradd(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!exotherchk}>
                  <IonLabel>Date of From</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exotherdetaildate}
                    onIonChange={(e) =>
                      setexotherdetaildate(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exotherchk}>
                  <IonLabel>Date of To</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={exotherdateto}
                    onIonChange={(e) =>
                      setexotherdateto(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!exotherchk}>
                  <IonItem>
                    <IonText className="ion-margin">
                      With ongoing COVID-19 community transmission?
                    </IonText>
                  </IonItem>
                  <IonRadioGroup
                    value={exothertrans}
                    onIonChange={(e) => setexothertrans(e.detail.value)}
                  >
                    <IonRow>
                      <IonItem lines="none">
                        <IonLabel>YES</IonLabel>
                        <IonRadio slot="start" value="1" color="dark" />
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>No</IonLabel>
                        <IonRadio slot="start" value="0" color="dark" />
                      </IonItem>
                    </IonRow>
                  </IonRadioGroup>
                </IonItem>

                <IonItem>
                  <IonCheckbox
                    slot="start"
                    color="light"
                    checked={extranspochk}
                    onIonChange={(e) =>
                      setextranspochk((extranspochk) => !extranspochk)
                    }
                  />
                  <IonLabel>Transportation</IonLabel>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Airline / Sea vessel / Bus line / Train{" "}
                    <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    required
                    type="text"
                    placeholder="1."
                    value={extransvessel.toUpperCase()}
                    onInput={(e: any) =>
                      setextransvessel(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>

                  <IonInput
                    required
                    type="text"
                    placeholder="2."
                    value={extransvessel2.toUpperCase()}
                    onInput={(e: any) =>
                      setextransvessel2(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Flight / Vessel / Bus No.{" "}
                    <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    required
                    type="text"
                    placeholder="1."
                    value={extransfvessel.toUpperCase()}
                    onInput={(e: any) =>
                      setextransfvessel(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                  <IonInput
                    required
                    type="text"
                    placeholder="2."
                    value={extransfvessel2.toUpperCase()}
                    onInput={(e: any) =>
                      setextransfvessel2(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Place of Origin <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    required
                    type="text"
                    placeholder="1."
                    value={extransorigin.toUpperCase()}
                    onInput={(e: any) =>
                      setextransorigin(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                  <IonInput
                    required
                    type="text"
                    placeholder="2."
                    value={extransorigin2.toUpperCase()}
                    onInput={(e: any) =>
                      setextransorigin2(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Origin Departure Date (MM/DD/YYYY){" "}
                    <IonText color="danger">*</IonText>
                  </IonLabel>

                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={extransdatedep}
                    placeholder="1."
                    onIonChange={(e) =>
                      setextransdatedep(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>

                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={extransdatedep2}
                    placeholder="2."
                    onIonChange={(e) =>
                      setextransdatedep2(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Destination <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    required
                    type="text"
                    placeholder="1."
                    value={extransdesti.toUpperCase()}
                    onInput={(e: any) =>
                      setextransdesti(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                  <IonInput
                    required
                    type="text"
                    placeholder="2."
                    value={extransdesti2.toUpperCase()}
                    onInput={(e: any) =>
                      setextransdesti2(e.target.value.toUpperCase())
                    }
                    clearInput
                  ></IonInput>
                </IonItem>

                <IonItem hidden={!extranspochk}>
                  <IonLabel position="stacked">
                    {" "}
                    Date of Arrival (MM/DD/YYYY){" "}
                    <IonText color="danger">*</IonText>
                  </IonLabel>

                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={extransarri}
                    placeholder="1."
                    onIonChange={(e) =>
                      setextransarri(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>

                  <IonDatetime
                    displayFormat="YYYY-MM-DD"
                    value={extransarri2}
                    placeholder="2."
                    onIonChange={(e) =>
                      setextransarri2(
                        Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                      )
                    }
                  ></IonDatetime>
                </IonItem>
              </IonCol>

              {/* <IonItem className="tabcolor">
                <IonText className="ion-margin">
                  - If symptomatic, provide names and contact numbers of persons
                  who were with the patient two days prior to onset of illness
                  until this date
                </IonText>
              </IonItem>
              <IonItem className="tabcolor">
                <IonText className="ion-margin">
                  - If asymptomatic, provide names and contact numbers of
                  persons who were with the patient on the day specimen was
                  submitted for testing until this date
                </IonText>
              </IonItem> */}
              {/* 
              <IonItem>
                <IonText>
                  If you dont have person with you just put{" "}
                  <IonText color="danger"> N/A</IonText>
                </IonText>
              </IonItem> */}

              <IonItemDivider>
                <IonText>
                  <b>
                    {" "}
                    - If symptomatic, provide names and contact numbers of
                    persons who were with the patient two days prior to onset of
                    illness until this date
                  </b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>

              <IonItemDivider>
                <IonText>
                  <b>
                    {" "}
                    - If asymptomatic, provide names and contact numbers of
                    persons who were with the patient on the day specimen was
                    submitted for testing until this date
                  </b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>
              <IonItemDivider>
                <IonText>
                  <b> - If you don't have one, please put N/A</b>
                  <IonText color="danger">*</IonText>
                </IonText>
              </IonItemDivider>

              <IonRow>
                <IonItem>
                  <IonLabel position="stacked">
                    {" "}
                    Name <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    placeholder="1."
                    value={exname1}
                    onInput={(e: any) => setexname1(e.target.value)}
                  ></IonInput>
                  <IonInput
                    placeholder="2."
                    value={exname2}
                    onInput={(e: any) => setexname2(e.target.value)}
                  ></IonInput>

                  <IonInput
                    placeholder="3."
                    value={exname3}
                    onInput={(e: any) => setexname3(e.target.value)}
                  ></IonInput>

                  <IonInput
                    placeholder="4."
                    value={exname4}
                    onInput={(e: any) => setexname4(e.target.value)}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">
                    {" "}
                    Contact Number <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    placeholder="1."
                    value={excontact1}
                    onInput={(e: any) => setcontact1(e.target.value)}
                  ></IonInput>
                  <IonInput
                    placeholder="2."
                    value={excontact2}
                    onInput={(e: any) => setcontact2(e.target.value)}
                  ></IonInput>

                  <IonInput
                    placeholder="3."
                    value={excontact3}
                    onInput={(e: any) => setcontact3(e.target.value)}
                  ></IonInput>

                  <IonInput
                    placeholder="4."
                    value={excontact4}
                    onInput={(e: any) => setcontact4(e.target.value)}
                  ></IonInput>
                </IonItem>
              </IonRow>
            </IonItemGroup>

            <IonButton
              shape="round"
              type="reset"
              color="success"
              onClick={() => setshowalert(true)}
            >
              Submit
              <IonIcon slot="start" icon={logIn} />
            </IonButton>
            {RenderAlert()}
            {test()}
            {RenderAddress()}
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default CovidReg;
