import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCheckbox,
  IonButton,
  IonIcon,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { logIn } from "ionicons/icons";
import Moment from "moment";
import Auth from "../components/Auth";

const Monitoring: React.FC<any> = ({ history }) => {
  const Datenow = Moment(new Date()).format("YYYY-MM-DD");

  const [temp, setTemp] = useState(0);
  const [bp, setBp] = useState("");

  const [cough, setCough] = useState(false);
  const [throat, setThroat] = useState(false);
  const [breath, setBreath] = useState(false);
  const [diarrhea, setDiarrhea] = useState(false);
  const [soreeyes, setSoreeyes] = useState(false);

  const [rashes, setRashes] = useState(false);
  const [colds, setcolds] = useState(false);
  const [ssmell, setssmell] = useState(false);
  const [staste, setstaste] = useState(false);
  const [exposure, setexpose] = useState(false);
  const [ypyes, setypyes] = useState(false);
  const [yptext, setyptext] = useState("");
  const [visityes, setvisityes] = useState(false);
  const [visittext, setvisittext] = useState("");

  const checktrue = true;
  const checkconditionload = () => {
    Auth.login(checkcondition, checktrue);
  };

  const checkcondition = () => {
    history.push({
      pathname: `/Onlinebook`,
      state: {
        mdate: Datenow,
        cough: cough === true ? "1" : "0",
        temp: temp,
        throat: throat === true ? "1" : "0",
        breath: breath === true ? "1" : "0",
        diarrhea: diarrhea === true ? "1" : "0",
        soreeyes: soreeyes === true ? "1" : "0",
        rashes: rashes === true ? "1" : "0",
        colds: colds === true ? "1" : "0",
        exposure: exposure === true ? "1" : "0",
        bp: bp,
        ssmell: ssmell === true ? "1" : "0",
        staste: staste === true ? "1" : "0",
        ypyes: ypyes === true ? "1" : "0",
        yptext: yptext,
        visityes: visityes === true ? "1" : "0",
        visittext: visittext,
      },
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle>Triage Form</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <form>
            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Temperature °C</IonLabel>
                  <IonInput
                    name="Temperature"
                    onIonChange={(e: any) => setTemp(e.target.value)}
                    type="number"
                    spellCheck={false}
                    required
                    clearInput
                    min="0"
                    max="42"
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Blood Pressure</IonLabel>
                  <IonInput
                    name="Blood Pressure"
                    onIonChange={(e: any) => setBp(e.target.value)}
                    type="tel"
                    spellCheck={false}
                    required
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      <b>Let us know if you developed symptoms</b>
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  {/* <IonCardSubtitle>Symptoms: <IonIcon slot="start" icon={addCircle} /></IonCardSubtitle> */}

                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={cough}
                      onIonChange={(e) => setCough((cough) => !cough)}
                    />
                    <IonLabel>
                      <b>I have Cough</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    An act that clears throat and breathing passages from
                    irritants. Serious cough are those with colored phlegm,
                    fever, wheezing and persisted for days. These need
                    professional attention.
                    <br></br> <br></br>
                    Isang pagkilos ng katawan na kini-clear ang lalamunan at
                    daluyan ng hangin sa mga irritants. Ang malubhang ubo ay
                    yaong may kulay na dahak, lagnat, paghuni ng hinga, at
                    tuloy-tuloy
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={throat}
                      onIonChange={(e) => setThroat((throat) => !throat)}
                    />
                    <IonLabel>
                      <b>I have Sore Throat</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Pain, scratchiness, or irritation of the throat that often
                    worsens when you swallow.
                    <br></br> <br></br>
                    Makati o iritasyon ng lalamunan at masakit sa paglunok.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={breath}
                      onIonChange={(e) => setBreath((breath) => !breath)}
                    />
                    <IonLabel>
                      <b>I am experiencing Shortness of Breath</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    An uncomfortable condition that makes it difficult to fully
                    get air into your lungs.
                    <br></br> <br></br>
                    Kondisyon na mahirap makakuha ng hangin sa baga.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={diarrhea}
                      onIonChange={(e) => setDiarrhea((diarrhea) => !diarrhea)}
                    />
                    <IonLabel>
                      <b>I have Diarrhea</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Condition of having at least three (3) loose or watery bowel
                    movements in a day.
                    <br></br> <br></br>
                    Kondisyon na may tatlong (3) beses na malambot o matubig na
                    pagdudumi sa isang araw.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={soreeyes}
                      onIonChange={(e) => setSoreeyes((soreeyes) => !soreeyes)}
                    />
                    <IonLabel>
                      <b>I have Sore Eyes</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Red, Swollen and Itchy of the eyes.
                    <br></br> <br></br>
                    Namumula , Namamaga at Makati ang mata.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={rashes}
                      onIonChange={(e) => setRashes((rashes) => !rashes)}
                    />
                    <IonLabel>
                      <b>I have Skin Rashes</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Painful red or purple lesions that typically emerge on
                    fingers or toes
                    <br></br> <br></br>
                    Masakit na pula o lilang mga sugat na karaniwang lumilitaw
                    sa mga daliri o daliri ng paa
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={colds}
                      onIonChange={(e) => setcolds((colds) => !colds)}
                    />
                    <IonText>
                      <b>I have Colds</b>
                    </IonText>
                  </IonItem>

                  <IonCardContent>
                    A cold is a contagious upper respiratory infection that
                    affects your nose, throat, sinuses and trachea
                    <br></br> <br></br>
                    Ang sipon ay isang nakakahawang impeksyon sa itaas na
                    respiratory na nakakaapekto sa iyong ilong, lalamunan, sinus
                    at trachea
                  </IonCardContent>
                </IonCard>

                <IonCard hidden={colds === true ? false : true}>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={ssmell}
                      onIonChange={(e) => setssmell((ssmell) => !ssmell)}
                    />
                    <IonText>
                      <b>Wala ng pangamoy. (No sense of smell anymore)</b>
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={staste}
                      onIonChange={(e) => setstaste((staste) => !staste)}
                    />
                    <IonText>
                      <b>Wala ng panlasa. (No sense of taste anymore)</b>
                    </IonText>
                  </IonItem>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={exposure}
                      onIonChange={(e) => setexpose((exposure) => !exposure)}
                    />
                    <IonText>
                      <b>
                        I have Exposure to Known COVID CASE Without Wearing PPE
                      </b>
                    </IonText>
                  </IonItem>

                  <IonCardContent>
                    History of Exposure to Known COVID-19 Case 14 days before
                    the onset of signs and symptoms:
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      <b>
                        {" "}
                        Aside from Work and Home, Have you been to other places
                        yesterday? (Indicate occasion/reason, then location)
                      </b>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={ypyes}
                      onIonChange={(e) => setypyes((ypyes) => !ypyes)}
                    />
                    <IonText>
                      <h3>If yes, When and Where (input below)</h3>
                    </IonText>
                  </IonItem>
                  <IonItem hidden={ypyes === true ? false : true}>
                    <IonInput
                      placeholder="(ex: Birthday : Alaminos , Kasal : Tiaong )"
                      onInput={(e: any) =>
                        setyptext(e.target.value.toUpperCase())
                      }
                      value={yptext.toUpperCase()}
                    ></IonInput>
                  </IonItem>
                </IonCard>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      <b>
                        {" "}
                        Did you have any Visitors yesterday from other places?
                      </b>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={visityes}
                      onIonChange={(e) => setvisityes((ypyes) => !ypyes)}
                    />
                    <IonText>
                      <h3>If yes, Indicate Place of origin (input below)</h3>
                    </IonText>
                  </IonItem>
                  <IonItem hidden={visityes === true ? false : true}>
                    <IonInput
                      placeholder="(NCR, Laguna)"
                      onInput={(e: any) =>
                        setvisittext(e.target.value.toUpperCase())
                      }
                      value={visittext.toUpperCase()}
                    ></IonInput>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-margin"
                  type="button"
                  color="success"
                  onClick={checkconditionload}
                >
                  Next
                  <IonIcon slot="start" icon={logIn} />
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Monitoring;
