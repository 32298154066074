class Auth {
  constructor() {
    this.authenticated = false;
    this.globalwebhostSPC = "https://link.spcmc.online/";
    this.globalwebhost = "https://api.spcmc.online/";

    this.typeoftest = "0";
  }

  login(cb, checktrue) {
    this.authenticated = checktrue;
    cb();
  }
  typeoftestd(cb, typetest) {
    this.typeoftest = typetest;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
