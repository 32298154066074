import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonIcon,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonCheckbox,
  IonCard,
  IonItemGroup,
  IonItemDivider,
  IonCardTitle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Moment from "moment";

import {
  addCircleSharp,
  calendarOutline,
  logIn,
  manOutline,
  save,
} from "ionicons/icons";
import "./Home.css";
import Auth from "../components/Auth";

// import {
//   FileTransfer,
//   FileUploadOptions,
//   FileTransferObject,
// } from "@ionic-native/file-transfer";
// import { File } from "@ionic-native/file";
// import { Camera } from "@ionic-native/camera";
// import { NONAME } from "dns";

interface Region {
  PK_addregion: number;
  addregion: String;
  FK_addregion: number;
}

interface Province {
  PK_addprovince: number;
  FK_addregion: number;
  provincename: String;
}

interface Municipality {
  PK_addmuncity: number;
  FK_addprovince: number;
  muncityname: String;
  zipcode: number;
}

interface Brgy {
  PK_addbrgy: number;
  FK_addmuncity: number;
  brgyname: String;
}

var Barcode = require("react-barcode");

const Onlinebook: React.FC<any> = ({ history, location }) => {
  const [DataSource, setDataSource] = useState<Region[]>([]);

  const [DataSource1, setDataSource1] = useState<Province[]>([]);

  const [DataSource2, setDataSource2] = useState<Municipality[]>([]);

  const [DataSource3, setDataSource3] = useState<Brgy[]>([]);

  const Datenow = Moment(new Date()).format("YYYY-MM-DD");

  const Datenow2 = Moment(new Date()).format("dddd, MMMM Do YYYY hh:mm a");

  //MODAL
  const [region, setregion] = useState(0);
  const [province, setprovince] = useState(0);
  const [muncity, setmuncity] = useState(0);
  const [brgy, setbrgy] = useState(0);
  // eslint-disable-next-line
  const [regiondata, setregiondata] = useState("");
  const [provincedata, setprovincedata] = useState("");
  const [muncitydata, setmuncitydata] = useState("");
  const [brgydata, setbrgydata] = useState("");
  const [addother, setaddother] = useState("");

  //START

  const [philhealthnum, setphilhealthnum] = useState("");
  const [namelast, setnamelast] = useState("");
  const [namefirst, setnamefirst] = useState("");
  const [namemiddle, setnamemiddle] = useState("");
  const [namesuffix, setnamesuffix] = useState("");
  const [datebirth, setdatebirth] = useState<string>(Datenow);
  const [gender, setgender] = useState("1");
  const [civilstatus, setcivilstatus] = useState("");
  const [nationality, setnationality] = useState("");
  const [occupation, setoccupation] = useState("");
  const [pachousest, setpachousest] = useState("");
  const [pacbrgy, setpacbrgy] = useState("");
  const [pacmuncity, setpacmuncity] = useState("");
  const [pacprovince, setpacprovince] = useState("");
  const [pachomephoneno, setpachomephoneno] = useState("");
  const [paccellphoneno, setpaccellphoneno] = useState("");
  const [pacemailadd, setpacemailadd] = useState("");
  const [trnameinfor, settrnameinfor] = useState("");
  const [trrelaship, settrrelaship] = useState("");
  const [trcontact, settrcontact] = useState("");

  // END
  const [refcovidid, setrefcovidid] = useState("");
  const [refmodalnumbmod, setrefmodalnumbmod] = useState(false);
  const [refmodaladdress, setrefmodaladdresss] = useState(false);
  const [dateprefered, setprefered] = useState<string>(Datenow);
  const [isHosprec, setHosprec] = useState(false);
  const [checkadd, setcheckadd] = useState(0);
  const [tservice, settservice] = useState("");
  const [ttesttype, setttesttype] = useState("0");
  const [compicon1, setcompicon1] = useState({ preview: "", raw: "" });
  const [compicon2, setcompicon2] = useState({ preview: "", raw: "" });
  const [compicon3, setcompicon3] = useState({ preview: "", raw: "" });
  const [compicon4, setcompicon4] = useState({ preview: "", raw: "" });
  const [compicon5, setcompicon5] = useState({ preview: "", raw: "" });
  const [view, setview] = useState(false);

  // const [cahousest, setcahousest] = useState("");
  // const [cabrgy, setcabrgy] = useState("");
  // const [camuncity, setcamuncity] = useState("");
  // const [caprovince, setcaprovince] = useState("");
  // const [cahomephoneno, setcahomephoneno] = useState("");
  // const [cacellphoneno, setcacellphoneno] = useState("");
  // const [caemailadd, setcaemailadd] = useState("");
  // const [cwhousest, setcwhousest] = useState("");
  // const [cwbrgy, setcwbrgy] = useState("");
  // const [cwmuncity, setcwmuncity] = useState("");
  // const [cwprovince, setcwprovince] = useState("");
  // const [cwhomephoneno, setcwhomephoneno] = useState("");
  // const [cwcellphoneno, setcwcellphoneno] = useState("");
  // const [cwemailadd, setcwemailadd] = useState("");
  // const [wclosesetting, setwclosesetting] = useState("0");
  useEffect(() => {
    FindRegionAdd();

    if (
      location.state.cough === 1 ||
      location.state.temp > 37.5 ||
      location.state.throat === 1 ||
      location.state.breath === 1 ||
      location.state.diarrhea === 1 ||
      location.state.soreeyes === 1 ||
      location.state.rashes === 1 ||
      location.state.colds === 1 ||
      location.state.exposure === 1 ||
      location.state.ssmell === 1 ||
      location.state.staste === 1 ||
      location.state.visityes === 1
    ) {
      setview(true);
    }

    // eslint-disable-next-line
  }, []);

  // function PrintRefno() {
  //   fetch(Auth.globalwebhost + "/print3/example/interface/windows-usb.php", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       refcovidid: refcovidid,
  //     }),
  //   });
  //   setrefmodalnumbmod(false);
  //   history.replace({
  //     pathname: `/home`,
  //   });

  // }

  function validsize5(e: any) {
    if (e.size > 2e6) {
      alert("Please upload a file smaller than 2 MB");
      return false;
    }
    setcompicon5({
      preview: URL.createObjectURL(e),
      raw: e,
    });
  }

  function validsize4(e: any) {
    if (e.size > 2e6) {
      alert("Please upload a file smaller than 2 MB");
      return false;
    }
    setcompicon4({
      preview: URL.createObjectURL(e),
      raw: e,
    });
  }

  function validsize3(e: any) {
    if (e.size > 2e6) {
      alert("Please upload a file smaller than 2 MB");
      return false;
    }
    setcompicon3({
      preview: URL.createObjectURL(e),
      raw: e,
    });
  }

  function validsize2(e: any) {
    if (e.size > 2e6) {
      alert("Please upload a file smaller than 2 MB");
      return false;
    }
    setcompicon2({
      preview: URL.createObjectURL(e),
      raw: e,
    });
  }

  function validsize1(e: any) {
    if (e.size > 2e6) {
      alert("Please upload a file smaller than 2 MB");
      return false;
    }
    setcompicon1({
      preview: URL.createObjectURL(e),
      raw: e,
    });
  }

  function FindRegionAdd() {
    // if (Auth.typeoftest === "0") {
    //   settypeholder("Reverse transcription polymerase chain reaction (RT-PCR)");
    // } else if (Auth.typeoftest === "1") {
    //   settypeholder("Rapid Antigen");
    // }
    fetch(Auth.globalwebhost + "bookingapp/address/addregion", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);

        setDataSource(responseJson);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addprovince", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson1) => {
        // console.log(responseJson);
        setDataSource1(responseJson1);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addmuncity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson2) => {
        // console.log(responseJson);

        setDataSource2(responseJson2);
      });

    fetch(Auth.globalwebhost + "bookingapp/address/addbrgy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson3) => {
        // console.log(responseJson);

        setDataSource3(responseJson3);
      });
  }

  function RenderAlert() {
    return (
      <IonGrid>
        <IonModal isOpen={refmodalnumbmod} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Generated Reference Number</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal ion-text-center">
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>SPCMC BOOKING APP</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                {/* <IonCol>{refcovidid}</IonCol> */}
                <IonCol className="ion-text-center">
                  <Barcode value={refcovidid} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText> Date Submitted: {Datenow2}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>Date of Test: {dateprefered}</IonText>
                </IonCol>
              </IonRow>

              {/* <IonRow className="ion-text-center">
                <IonCol>
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={PrintRefno}
                  >
                    Print Reference Number
                    <IonIcon slot="start" icon={print} />
                  </IonButton>
                </IonCol>
              </IonRow>  */}

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={() => {
                      history.replace({
                        pathname: `/home`,
                      });

                      setrefmodalnumbmod(false);
                    }}
                  >
                    Close
                    <IonIcon slot="start" icon={save} />
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonLabel>
                    <IonText color="danger">*</IonText>
                    Please Take a Picture of your Reference Number
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    );
  }
  function RenderAddress() {
    return (
      <IonGrid>
        <IonModal isOpen={refmodaladdress} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Find Address</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal ion-text-center">
              <IonItem>
                <IonLabel>Region</IonLabel>
                <IonSelect
                  placeholder="Please select a [Region]."
                  value={region}
                  onIonChange={(e: any) => {
                    setregion(e.target.value);
                    const regiond = DataSource.filter(
                      (x) => x.PK_addregion === e.detail.value
                    );
                    setregiondata(regiond[0].addregion.toString());
                  }}
                >
                  {DataSource.map((m) => (
                    <IonSelectOption
                      key={m.PK_addregion}
                      value={m.PK_addregion}
                    >
                      {m.addregion}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Province</IonLabel>
                <IonSelect
                  placeholder="Please select a [Province]."
                  value={province}
                  onIonChange={(e: any) => {
                    setprovince(e.target.value);
                    const regiond = DataSource1.filter(
                      (x) => x.PK_addprovince === e.detail.value
                    );
                    setprovincedata(regiond[0].provincename.toString());
                  }}
                >
                  {DataSource1.filter(
                    (DataSource1) => DataSource1.FK_addregion === region
                  ).map((m) => (
                    <IonSelectOption
                      key={m.PK_addprovince}
                      value={m.PK_addprovince}
                    >
                      {m.provincename}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Municipal</IonLabel>
                <IonSelect
                  placeholder="Please select a [Municipality]."
                  value={muncity}
                  onIonChange={(e: any) => {
                    setmuncity(e.target.value);
                    const regiond = DataSource2.filter(
                      (x) => x.PK_addmuncity === e.detail.value
                    );
                    setmuncitydata(regiond[0].muncityname.toString());
                  }}
                >
                  {DataSource2.filter(
                    (DataSource2) => DataSource2.FK_addprovince === province
                  ).map((m) => (
                    <IonSelectOption
                      key={m.PK_addmuncity}
                      value={m.PK_addmuncity}
                    >
                      {m.muncityname}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>Barangay</IonLabel>
                <IonSelect
                  placeholder="Please select a [Barangay]."
                  value={brgy}
                  onIonChange={(e: any) => {
                    setbrgy(e.target.value);
                    const regiond = DataSource3.filter(
                      (x) => x.PK_addbrgy === e.detail.value
                    );
                    setbrgydata(regiond[0].brgyname.toString());
                  }}
                >
                  {DataSource3.filter(
                    (DataSource3) => DataSource3.FK_addmuncity === muncity
                  ).map((m) => (
                    <IonSelectOption key={m.PK_addbrgy} value={m.PK_addbrgy}>
                      {m.brgyname}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>No./St./Subd.:</IonLabel>
                <IonInput
                  required
                  type="text"
                  value={addother.toUpperCase()}
                  onInput={(e: any) =>
                    setaddother(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonButton
                    className="ion-margin "
                    type="submit"
                    color="success"
                    onClick={() => {
                      if (checkadd === 2) {
                        setpachousest(addother);
                        setpacbrgy(brgydata);
                        setpacmuncity(muncitydata);
                        setpacprovince(provincedata);
                        setrefmodaladdresss(false);
                      }
                    }}
                  >
                    Save
                    <IonIcon slot="start" icon={save} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    );
  }

  const InsertCovidPatient = async (e: any) => {
    const fetchAsBlob1 = (url: any) =>
      fetch(url).then((response) => response.blob());

    const fetchAsBlob2 = (url: any) =>
      fetch(url).then((response) => response.blob());

    const fetchAsBlob3 = (url: any) =>
      fetch(url).then((response) => response.blob());

    const fetchAsBlob4 = (url: any) =>
      fetch(url).then((response) => response.blob());

    const fetchAsBlob5 = (url: any) =>
      fetch(url).then((response) => response.blob());

    const convertBlobToBase1 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const convertBlobToBase2 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const convertBlobToBase3 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const convertBlobToBase4 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const convertBlobToBase5 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const blobUrl1 = compicon1.preview;
    const blob1 = await fetchAsBlob1(blobUrl1)
      .then(convertBlobToBase1)
      .then((byte: any) => {
        return byte;
      });

    const blobUrl2 = compicon2.preview;

    const blob2 = await fetchAsBlob2(blobUrl2)
      .then(convertBlobToBase2)
      .then((base64: any) => {
        return base64;
      });

    const blobUrl3 = compicon3.preview;

    const blob3 = await fetchAsBlob3(blobUrl3)
      .then(convertBlobToBase3)
      .then((base64: any) => {
        return base64;
      });

    const blobUrl4 = compicon4.preview;

    const blob4 = await fetchAsBlob4(blobUrl4)
      .then(convertBlobToBase4)
      .then((base64: any) => {
        return base64;
      });

    const blobUrl5 = compicon5.preview;

    const blob5 = await fetchAsBlob5(blobUrl5)
      .then(convertBlobToBase5)
      .then((base64: any) => {
        return base64;
      });

    // console.log(
    //   JSON.stringify({
    //     mdate: location.state.mdate,
    //     cough: location.state.cough,
    //     temp: location.state.temp,
    //     throat: location.state.throat,
    //     breath: location.state.breath,
    //     diarrhea: location.state.diarrhea,
    //     soreeyes: location.state.soreeyes,
    //     rashes: location.state.rashes,
    //     colds: location.state.colds,
    //     exposure: location.state.exposure,
    //     bp: location.state.bp,
    //     ssmell: location.state.ssmell,
    //     staste: location.state.staste,
    //     ypyes: location.state.ypyes,
    //     yptext: location.state.yptext,
    //     visityes: location.state.visityes,
    //     visittext: location.state.visittext,

    //     // onlinebook
    //     dateprefered: dateprefered,
    //     isHospRec: isHosprec === true ? "1" : "0",
    //     trnameinfor: trnameinfor,
    //     trrelaship: trrelaship,
    //     trcontact: trcontact,
    //     ttesttype: ttesttype,
    //     tservice: tservice,
    //     patientlast: namelast,
    //     patientfirst: namefirst,
    //     patientmiddle: namemiddle,
    //     patientsuffix: namesuffix,
    //     patientbdate: datebirth,
    //     patientgender: gender,
    //     civilstatus: civilstatus,
    //     nationality: nationality,
    //     occupation: occupation,
    //     philhealthno: philhealthnum,
    //     pachousest: pachousest,
    //     pacbrgy: pacbrgy,
    //     pacmuncity: pacmuncity,
    //     pacprovince: pacprovince,
    //     pachomephoneno: pachomephoneno,
    //     paccellphoneno: paccellphoneno,
    //     pacemailadd: pacemailadd,
    //     file1: blob1,
    //     file2: blob2,
    //     file3: blob3,
    //     file4: blob4,
    //     file5: blob5,
    //   })
    // );
    if (
      datebirth &&
      dateprefered &&
      namelast &&
      namefirst &&
      namemiddle &&
      gender !== ""
    ) {
      fetch(Auth.globalwebhostSPC + "/ODSBook/odsnewinsert.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mdate: location.state.mdate,
          cough: location.state.cough,
          temp: location.state.temp,
          throat: location.state.throat,
          breath: location.state.breath,
          diarrhea: location.state.diarrhea,
          soreeyes: location.state.soreeyes,
          rashes: location.state.rashes,
          colds: location.state.colds,
          exposure: location.state.exposure,
          bp: location.state.bp,
          ssmell: location.state.ssmell,
          staste: location.state.staste,
          ypyes: location.state.ypyes,
          yptext: location.state.yptext,
          visityes: location.state.visityes,
          visittext: location.state.visittext,

          // onlinebook
          dateprefered: dateprefered,
          isHospRec: isHosprec === true ? "1" : "0",
          trnameinfor: trnameinfor,
          trrelaship: trrelaship,
          trcontact: trcontact,
          ttesttype: ttesttype,
          tservice: tservice,
          patientlast: namelast,
          patientfirst: namefirst,
          patientmiddle: namemiddle,
          patientsuffix: namesuffix,
          patientbdate: datebirth,
          patientgender: gender,
          civilstatus: civilstatus,
          nationality: nationality,
          occupation: occupation,
          philhealthno: philhealthnum,
          pachousest: pachousest,
          pacbrgy: pacbrgy,
          pacmuncity: pacmuncity,
          pacprovince: pacprovince,
          pachomephoneno: pachomephoneno,
          paccellphoneno: paccellphoneno,
          pacemailadd: pacemailadd,
          file1: blob1,
          file2: blob2,
          file3: blob3,
          file4: blob4,
          file5: blob5,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.barcode !== 0) {
            setrefcovidid(responseJson.barcode);
            setrefmodalnumbmod(true);
          }
        })
        .catch((error) => {
          alert(error.toString());
        });
    } else {
      if (dateprefered === "") {
        alert("Date Preferred is Empty");
      } else if (datebirth === "") {
        alert("Birthday is Empty");
      } else if (gender === "") {
        alert("Gender is Empty");
      } else if (tservice === "") {
        alert("Select Service ");
      } else {
        alert("PLEASE FILL IN THE BLANKS WITH ASTERISK*");
      }
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder ">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonCol>
              {/* <IonTitle>SPCMC Booking App</IonTitle> */}
              <IonRow>
                <img
                  src="assets/icon/logowhite.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>

                <IonText className="imgcontainer">
                  Online Booking <br></br>
                  {/* {typeholder} */}
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form>
          <IonRow>
            <IonCol>
              <IonCardTitle className="ion-margin-horizontal ion-margin-top ">
                {" "}
                REGISTRATION:
              </IonCardTitle>
              <IonText className="ion-margin-horizontal ion-margin-top">
                {" "}
                Please fill in the blanks with asterisk
                <IonText color="danger">*</IonText>
              </IonText>
            </IonCol>
          </IonRow>
          <IonCard>
            <IonItem>
              <IonIcon slot="end" icon={calendarOutline} />
              <IonLabel>
                {" "}
                <b>
                  Date Preferred<IonText color="danger">*</IonText>
                </b>
              </IonLabel>
              <IonDatetime
                min={Moment(new Date(Datenow)).format("YYYY-MM-DD")}
                displayFormat="YYYY-MM-DD"
                value={dateprefered}
                onIonChange={(e) =>
                  setprefered(
                    Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                  )
                }
              ></IonDatetime>
            </IonItem>

            <IonItem>
              <IonCheckbox
                color="dark"
                checked={isHosprec}
                onIonChange={(e) => setHosprec(e.detail.checked)}
                slot="start"
              ></IonCheckbox>
              <IonText>
                <b> Do you already have a record with us?</b>
              </IonText>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Name of Informant <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trnameinfor}
                onInput={(e: any) => settrnameinfor(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Relationship <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trrelaship}
                onInput={(e: any) => settrrelaship(e.target.value)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Contact No <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                value={trcontact}
                onInput={(e: any) => settrcontact(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItemGroup>
              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  PART 1: Patient Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonLabel>
                  <b>Please Select a Type of Service?</b>
                </IonLabel>
                <IonSelect
                  value={tservice}
                  onIonChange={(e) => settservice(e.detail.value)}
                >
                  <IonSelectOption disabled={view} value="0">
                    Appointment
                  </IonSelectOption>
                  <IonSelectOption value="1">Home Service</IonSelectOption>
                  <IonSelectOption value="2">Park and Test</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel>
                  <b>Please Select a Type of TEST</b>
                </IonLabel>
                <IonSelect
                  value={ttesttype}
                  onIonChange={(e) => setttesttype(e.detail.value)}
                >
                  <IonSelectOption value="0">Laboratory</IonSelectOption>
                  <IonSelectOption value="1">Radiology</IonSelectOption>
                  <IonSelectOption value="2">Pulmo</IonSelectOption>
                  <IonSelectOption value="3">Cardio Vascular</IonSelectOption>
                  <IonSelectOption value="4">Pharmacy</IonSelectOption>
                  <IonSelectOption value="5">PT Rehab</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.1. Patient Profile
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Last Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  // autoCapitalize="on"
                  required
                  type="text"
                  value={namelast.toUpperCase()}
                  onInput={(e: any) =>
                    setnamelast(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  First Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={namefirst.toUpperCase()}
                  onInput={(e: any) =>
                    setnamefirst(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonCol>
                  <IonRow>
                    <IonLabel position="stacked">
                      {" "}
                      Middle Name <IonText color="danger">*</IonText>
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      value={namemiddle.toUpperCase()}
                      onInput={(e: any) =>
                        setnamemiddle(e.target.value.toUpperCase())
                      }
                      clearInput
                    ></IonInput>

                    <IonLabel position="stacked"> Name Suffix</IonLabel>
                    <IonInput
                      required
                      type="text"
                      value={namesuffix.toUpperCase()}
                      onInput={(e: any) =>
                        setnamesuffix(e.target.value.toUpperCase())
                      }
                      clearInput
                    ></IonInput>
                  </IonRow>
                </IonCol>
              </IonItem>

              <IonItem>
                <IonIcon slot="end" icon={calendarOutline} />
                <IonLabel>
                  <b>Birth Day</b>
                </IonLabel>
                <IonDatetime
                  displayFormat="YYYY-MM-DD"
                  value={datebirth}
                  onIonChange={(e) =>
                    setdatebirth(
                      Moment(new Date(e.detail.value!)).format("YYYY-MM-DD")
                    )
                  }
                ></IonDatetime>
              </IonItem>
              <IonItem>
                <IonIcon slot="end" icon={manOutline} />
                <IonLabel>
                  <b>Gender</b>
                </IonLabel>
                <IonSelect
                  value={gender}
                  onIonChange={(e) => setgender(e.detail.value)}
                >
                  <IonSelectOption value="1">Male</IonSelectOption>
                  <IonSelectOption value="0">Female</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Civil Status <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={civilstatus.toUpperCase()}
                  onInput={(e: any) =>
                    setcivilstatus(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Nationality <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={nationality.toUpperCase()}
                  onInput={(e: any) =>
                    setnationality(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Occupation <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  value={occupation.toUpperCase()}
                  onInput={(e: any) =>
                    setoccupation(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Philhealth No <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  // autoCapitalize="on"
                  required
                  type="text"
                  value={philhealthnum.toUpperCase()}
                  onInput={(e: any) =>
                    setphilhealthnum(e.target.value.toUpperCase())
                  }
                  clearInput
                ></IonInput>
              </IonItem>

              <IonItemDivider>
                <IonText>
                  {" "}
                  <h3>
                    {" "}
                    Click <IonIcon slot="end" icon={addCircleSharp} /> to
                    auto-select the address in PART 1.2
                  </h3>{" "}
                </IonText>
              </IonItemDivider>

              <IonItemDivider className="tabcolor">
                <IonText className="ion-margin-vertical">
                  1.2. Permanent Address and Contact Information
                </IonText>
              </IonItemDivider>

              <IonItem>
                <IonLabel position="stacked"></IonLabel>

                <IonIcon
                  slot="start"
                  icon={addCircleSharp}
                  onClick={() => {
                    setrefmodaladdresss(true);
                    setcheckadd(2);
                  }}
                />

                <IonInput
                  placeholder="House No./Lot/Bldg and Street:"
                  value={pachousest.toUpperCase()}
                  onInput={(e: any) =>
                    setpachousest(e.target.value.toUpperCase())
                  }
                ></IonInput>

                <IonInput
                  placeholder="Barangay:"
                  value={pacbrgy.toUpperCase()}
                  onInput={(e: any) => setpacbrgy(e.target.value.toUpperCase())}
                ></IonInput>
                <IonInput
                  placeholder="Municipality/City"
                  value={pacmuncity.toUpperCase()}
                  onInput={(e: any) =>
                    setpacmuncity(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Province"
                  value={pacprovince.toUpperCase()}
                  onInput={(e: any) =>
                    setpacprovince(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Home Phone No:"
                  value={pachomephoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setpachomephoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="Cellphone No:"
                  value={paccellphoneno.toUpperCase()}
                  onInput={(e: any) =>
                    setpaccellphoneno(e.target.value.toUpperCase())
                  }
                ></IonInput>
                <IonInput
                  placeholder="E-mail Address:"
                  value={pacemailadd}
                  onInput={(e: any) => setpacemailadd(e.target.value)}
                ></IonInput>
              </IonItem>
            </IonItemGroup>

            <IonItem>
              <IonLabel position="fixed">1. Req.from Dr.:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) => validsize1(e.target.files[0])}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">2. Valid ID:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) => validsize2(e.target.files[0])}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">3. Senior ID:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) => validsize3(e.target.files[0])}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">4. HMO:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) => validsize4(e.target.files[0])}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">5. Others:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) => validsize5(e.target.files[0])}
              />
            </IonItem>

            <IonButton
              shape="round"
              type="reset"
              color="success"
              onClick={() => InsertCovidPatient(true)}
            >
              Submit
              <IonIcon slot="start" icon={logIn} />
            </IonButton>
            {RenderAlert()}
            {RenderAddress()}
          </IonCard>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Onlinebook;
